import React, { useState } from "react";
import { Section, CardEleven } from "master-react-lib";
import Grid from "@material-ui/core/Grid";
import Title from "./Title";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";

import DailyJummah from "../assets/imgs/services/daily-Jummah.png";
import Ramadan from "../assets/imgs/services/ramadan-Iftar-and-tarawih.png";
import EidPrayers from "../assets/imgs/services/eid-prayers.png";
import MonthlyFamily from "../assets/imgs/services/monthly-family-night.png";

function OurServices(props) {
  const eventsData = [
    {
      img: DailyJummah,
      title: "Daily and Jumu'ah Prayers",
      link: "/about-us#our-services",
    },

    {
      img: Ramadan,
      title: "Taraweeh",
      link: "/about-us#our-services",
    },
    {
      img: EidPrayers,
      title: "Interfaith dialogues",
      link: "/about-us#our-services",
    },
    {
      img: MonthlyFamily,
      title: "Family counselling",
      link: "/about-us#our-services",
    },
  ];

  return (
    <div className="InnerSection" id="services">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Title
            Title="OUR SERVICES"
            ButtonLabel="All Services"
            ButtonStatus="true"
            link="/about-us#our-services"
            arrowStatus={true}
            background="#0B132B"
          />
        </Grid>

        {eventsData.map((item, i) => (
          <Grid item xs={12} md={3}>
            <CardEleven
              img={item.img}
              ImgHeight="144px"
              title={item.title}
              link={item.link}
              ImgSpace="3px"
              titleColor="#0A2540"
              buttonLabel="View Details"
              ReadmoreIcon={<ArrowForwardIosRoundedIcon />}
              ReadmoreColor="#676C7B"
              border="underline"
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default OurServices;
