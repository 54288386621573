import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import OurServices from "../components/OurServices";
import Grid from "@material-ui/core/Grid";
import SideBar from "../components/SideBar";
import {
  Section,
  PageTitleTwo,
  VideoCardOne,
  VideoPlayerModal,
  ButtonOne,
} from "master-react-lib";
import bannerImg from "../assets/imgs/banner.png";
import videoImg from "../assets/imgs/video.png";
import PlayCircleOutlineRoundedIcon from "@material-ui/icons/PlayCircleOutlineRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { getVideos } from "../components/Functions";
const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function Videos() {
  const [index, setIndex] = useState(null);

  const videoArray = [];
  const [videosData, setVideoData] = useState([]);
  const [showMoreVideos, setShowMoreVideos] = useState(false);
  const getYouTubeID = (url) => {
    var ID = "";
    url = url
      .replace(/(>|<)/gi, "")
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_\-]/i);
      ID = ID[0];
    } else {
      ID = url;
    }
    return ID;
  };

  useEffect(async () => {
    const videos = await getVideos(orgId, "deenweb");
    let temp = [];

    if (videos.data.body.data) {
      videos.data.body.data.map((element) => {
        let obj = {
          img: element.video.link,
          title: element.video.title,
          link: element.video.link,
          status: element.status,
        };
        element.status === "Public" && temp.push(obj);
      });
      setVideoData(temp);
    }
  }, []);

  const breadcrumbsData = [{ page: "HOME", paht: "/" }];

  const [isOpen, setOpen] = useState(false);
  const playVideo = (i) => {
    setIndex(i);
    setTimeout(() => {
      setOpen(true);
    }, 500);
    console.log("index is", i);
  };

  return (
    <div className="msjHContainer">
      <Header />
      <div className="InnerPageBody">
        <PageTitleTwo
          Title="VIDEOS"
          color="#fff"
          backgroundcolor="#029930"
          height="auto"
          breadcrumbsData={breadcrumbsData}
          currentPage="VIDEOS"
        />

        <Section
          background="#fff"
          //ContainerWidth=""
          Spacing="40px"
          Container={
            <div className="msjHBody">
              <Grid container spacing={3}>
                <Grid item xs={12} md={9}>
                  {showMoreVideos ? (
                    <Grid container spacing={3}>
                      {videosData &&
                        videosData.map((item, i) => {
                          let videoId = getYouTubeID(item.img);
                          videoArray.push(videoId);

                          let videoThumb = `https://i.ytimg.com/vi/${videoId}/default.jpg`;
                          return (
                            <Grid item xs={12} md={4} sm={6}>
                              <div
                                className="videocards"
                                onClick={() => playVideo(i)}
                              >
                                <VideoCardOne
                                  img={videoThumb}
                                  title={item.title}
                                  ImgSpace="3px"
                                  titleColor="#0A2540"
                                  border="underline"
                                  icon={<PlayCircleOutlineRoundedIcon />}
                                  ImgHeight="175px"
                                />
                              </div>
                            </Grid>
                          );
                        })}

                      {videosData.length > 9 && (
                        <Grid item xs={12} md={12}>
                          <div className="LoadMore">
                            <ButtonOne
                              label="Load More Videos"
                              link=""
                              arrowIcon={<ArrowForwardIosRoundedIcon />}
                              arrowStatus={true}
                              arrowColor="#fff"
                              buttoncolor="#029930"
                              width="100%"
                              height="50px"
                              border="#ccc 0px solid"
                              borderRadius="4px"
                              color="#fff"
                              fontWeight="600"
                              textAlign="center"
                              onClick={() => setShowMoreVideos(true)}
                            />
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  ) : (
                    <Grid container spacing={3}>
                      {videosData &&
                        videosData.map((item, i) => {
                          if (i <= 8) {
                            let videoId = getYouTubeID(item.img);
                            videoArray.push(videoId);
                            // let videoId = getThumb(item.img);
                            let videoThumb = `https://i.ytimg.com/vi/${videoId}/default.jpg`;
                            return (
                              <Grid item xs={12} md={4} sm={6}>
                                <div
                                  className="videocards"
                                  onClick={() => playVideo(i)}
                                >
                                  <VideoCardOne
                                    img={videoThumb}
                                    title={item.title}
                                    ImgSpace="3px"
                                    titleColor="#0A2540"
                                    border="underline"
                                    icon={<PlayCircleOutlineRoundedIcon />}
                                    ImgHeight="175px"
                                  />
                                </div>
                              </Grid>
                            );
                          }
                        })}

                      {videosData.length > 9 && (
                        <Grid item xs={12} md={12}>
                          <div className="LoadMore">
                            <ButtonOne
                              label="Load More Videos"
                              link=""
                              arrowIcon={<ArrowForwardIosRoundedIcon />}
                              arrowStatus={true}
                              arrowColor="#fff"
                              buttoncolor="#029930"
                              width="100%"
                              height="50px"
                              border="#ccc 0px solid"
                              borderRadius="4px"
                              color="#fff"
                              fontWeight="600"
                              textAlign="center"
                              onClick={() => setShowMoreVideos(true)}
                            />
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
                <div>
                  <VideoPlayerModal
                    data={index}
                    vidoeID={videoArray}
                    isOpen={isOpen}
                    onClose={() => {
                      setOpen(false);
                      setIndex(null);
                    }}
                  />
                </div>
                <Grid item xs={12} md={3}>
                  <SideBar />
                </Grid>
              </Grid>

              <OurServices />
            </div>
          }
        />

      </div>

      <Footer />
    </div>
  );
}

export default Videos;
