import React, { useEffect, useState } from "react";
import { SalatTable } from "master-react-lib";
import Sunrise from "../assets/imgs/Sunrise.png";
import Sun from "../assets/imgs/Sun.png";
import Moon from "../assets/imgs/Moon.png";
import { getSalahs, getOrganization } from "./Functions";
import moment from "moment";
const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function IqamaTime() {
  const getTimeStamp = (time) => {
    let timestamp = moment(time).format("x");
    return timestamp;
  };
  const [lastUpdated, setlastUpdated] = useState();
  const [tableBodyData, settableBodyData] = useState([]);
  useEffect(async () => {
    const resSalahGet = await getSalahs(orgId, "deenweb");

    const SalahData = resSalahGet?.data?.body?.data;

    const ActiveSalah = SalahData.filter((text) => text.status === "true");

    const resOrg = await getOrganization(orgId);
    const orgIntegate = resOrg.data?.body?.data?.enabledIntegrations;

    const salah = orgIntegate?.deenweb?.salahs?.selectedSalahs;

    const d1 = new Date();
    const d = Number(d1);
    const resData = salah.filter((item) => item.status === "Public"); //&& Number(new Date(item.publishFrom)) < d && Number(new Date(item.publishTo)) > d)

    const Ids = resData.length ? resData.map((t) => t.salah) : [];

    let updatedSalahObj = [];

    const SalahToDisplay = ActiveSalah.filter((text) => Ids.includes(text._id));

    let sortedSalah = SalahToDisplay?.filter((el) => el.order).sort((a, b) => {
      return a.order - b.order;
    });

    sortedSalah.sort((a, b) => a.order - b.order);

    const lu = sortedSalah?.length
      ? sortedSalah.sort((a, b) => {
          return getTimeStamp(a.updatedAt) - getTimeStamp(b.updatedAt);
        })
      : [];

    let tempData = [];

    if (lu && Array.isArray(lu)) {
      setlastUpdated(lu?.[0]?.updatedAt);

      lu.map((element) => {
        let name = element?.salahName?.fullName?.split("(");

        name = name?.length ? name[0] : salah?.salahName.fullName;
        let salahTime = element?.salahStart?.iqamahTime
          ? moment(element?.salahStart?.iqamahTime).utcOffset(0).format("LT")
          : "";
        let object = {
          col1: name,
          col2: salahTime,
          icon: element.salahName.fullName.includes("Dawn") ? (
            <img src={Sunrise} />
          ) : element.salahName.fullName.includes("Sunset") ||
            element.salahName.fullName.includes("Night") ? (
            <img src={Moon} />
          ) : (
            <img src={Sun} />
          ),
        };
        tempData.push(object);
      });

      settableBodyData(tempData);
    }
  }, []);

  return (
    <SalatTable
      TableTitle="IQAMAH TIME"
      tabletitleStatus={true}
      title={"HAJJ 2021 PACKAGES"}
      TitleColor="#FFFFFF"
      TitleBackground="#029930"
      tableBodyData={tableBodyData}
      footerText={
        lastUpdated && (
          <p>
            Last Updated {lastUpdated ? moment(lastUpdated).format("LL") : ""} /{" "}
            {lastUpdated
              ? moment(lastUpdated).utcOffset(-5).format("h:mm A") + " EST"
              : ""}
          </p>
        )
      }
      footerTextStatus={true}
      bottomTextSpace="15px"
      TableBackground="#fff"
      tbaleBorder="#0053A5 0px solid"
      TalePadding="0 10px"
      odd="#fff"
      even="#fff"
      colBorder="#E4E8EC 1px solid"
      col1Color="#029930"
      col1fontWeight="600"
      col2Color="#029930"
      col2fontWeight="600"
      ColfontSizet="14px"
    />
  );
}

export default IqamaTime;
