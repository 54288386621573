import React from "react";
import { NavBarThree } from "master-react-lib";
import Logo from "../assets/imgs/deenconnect-masjid-umme-hani-ra-florida.svg";
import MenuIcon from "../assets/imgs/menuIcon.svg";
import CloseIcon from "../assets/imgs/closeIcon.svg";

import facebook from "../assets/imgs/facebook.png";
import youtube from "../assets/imgs/youtube.png";
import twitter from "../assets/imgs/twitter.png";

import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";

function Header() {
  const menuData = [
    {
      label: "HOME",
      link: "/",
    },

    {
      label: "ABOUT US",
      hasChildren: true,
      children: [
        { label: "ABOUT US", link: "/about-us#about" },
        { label: "MISSION & VISION", link: "/about-us#vision" },
        { label: "OUR SERVICES", link: "/about-us#our-services" },
        { label: "OUR TEAM", link: "/about-us#our-team" },
        { label: "CONTACT US", link: "/about-us#contactus" },
      ],
    },

    {
      label: "SERVICES",
      link:"/about-us#our-services",
      hasChildren: false,
    },

    {
      label: "VIDEOS",
      link: "/videos",
      hasChildren: false,
    },

    {
      label: "EVENTS",
      link: "/events",
      hasChildren: false,
    },

    {
      label: "FAQ",
      link: "/faq",
      hasChildren: false,
    },
  ];

  const socialIconData = [
    {
      icon: <img src={facebook} />,
      iconColor: "#1877F2",
      link: "https://www.facebook.com/masjidhani/",
    },

    // {
    //   icon: <img src={youtube} />,
    //   iconColor: "#F44336",
    //   link: "https://www.youtube.com/",
    // },

    // {
    //   icon: <img src={twitter} />,
    //   iconColor: "#03A9F4",
    //   link: "https://twitter.com/",
    // },
  ];

  return (
    <div>
      <NavBarThree
        menuData={menuData}
        MenuStatus={true}
        logo={Logo}
        logoLink="/"
        MenuIcon={MenuIcon}
        CloseIcon={CloseIcon}
        background="#fff"
        maxWidth="1118px"
        LogoSize="100px"
        position="relative"
        borderLine="#E4E8EC"
        CalliconBg="#0053A5"
        CalliconColor="#fff"
        phonenumberColor="#000"
        phone="+1 (786) 523-6013"
        Cta="tel: +1 (786) 523-6013"
        socialIconData={socialIconData}
        vrLineBg="#ccc"
        //// Button Style ////
        buttonLebel="DONATE NOW"
        ButtonLink="https://give.deenfund.com/masjid-umme-hani"
        target="blank"
        arrowIcon={<ArrowForwardIosRoundedIcon />}
        arrowStatus={false}
        arrowColor="#fff"
        buttoncolor="#FF6200"
        width="auto"
        height="42px"
        border="#ccc 0px solid"
        borderRadius="0px"
        colorlabel="#fff"
        fontWeight="600"
        textAlign="center"
        handIconStatus={false}
      />
    </div>
  );
}

export default Header;
