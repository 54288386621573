import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Videos from "./pages/Videos";
import Events from "./pages/Events";
import About from "./pages/About";
import EventDetails from "./pages/EventDetails";
import Faq from "./pages/Faq";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";

import "../src/assets/css/style.css";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/videos" element={<Videos />} />
      <Route path="/events" element={<Events />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/events-details" element={<EventDetails />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/terms-of-services" element={<TermsOfService />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  );
}

export default App;
