import React from "react";
import { ContactUsThree } from "master-react-lib";
import Title from "./Title";
import Grid from "@material-ui/core/Grid";

function ContactUs(props) {
  const onContactFormSubmit = (contactFormData) => {
    console.log("contactFormData", contactFormData);
  };

  const selectdata = [
    { value: 1, select: "General Queries" },
    { value: 2, select: "Services/Activities" },
    { value: 3, select: "Masjid Tour" },
    { value: 4, select: "Donations" },
    { value: 5, select: "Volunteer" },
    { value: 6, select: "Media/Press" },
  ];

  return (
    <div className="SectionContainer">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} className="noBtn">
          <Title
            Title="CONTACT US"
            ButtonStatus="false"
            arrowStatus={false}
            background="#0B132B"
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <ContactUsThree
            onContactFormSubmit={onContactFormSubmit}
            selectdata={selectdata}
            ButtonLabel="SEND MESSAGE"
            ButtonColor="#56B149"
            // disableBtn="disabled"
            //phoneNumberError="error"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default ContactUs;
