import React, { useState, useEffect } from "react";
import { VideoCardOne, VideoPlayerModal } from "master-react-lib";
import Grid from "@material-ui/core/Grid";
import Title from "./Title";
import PlayCircleOutlineRoundedIcon from "@material-ui/icons/PlayCircleOutlineRounded";
import bannerImg from "../assets/imgs/banner.png";
import videoImg from "../assets/imgs/video.png";
import { getVideos } from "./Functions";
const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function VideosHome(props) {
  const videoArray = [];
  const [videosData, setVideoData] = useState();
  const [index, setIndex] = useState(0);
  const getYouTubeID = (url) => {
    var ID = "";
    url = url
      .replace(/(>|<)/gi, "")
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_\-]/i);
      ID = ID[0];
    } else {
      ID = url;
    }
    return ID;
  };
  useEffect(async () => {
    const videos = await getVideos(orgId, "deenweb");
    let temp = [];

    if (videos.data.body.data) {
      videos.data.body.data.map((element) => {
        let obj = {
          img: element.video.link,
          title: element.video.title,
          link: element.video.link,
          order: element.video.order,
        };
        element.featured && temp.push(obj);
      });
      temp.sort((a, b) => a.order - b.order);
      setVideoData(temp);
    }
  }, []);

  const [isOpen, setOpen] = useState(false);
  const playVideo = (i) => {
    setIndex(i - 1);
    setOpen(true);
  };

  return (
    <div className="InnerSection">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Title
            Title="WATCH"
            ButtonLabel="All Videos"
            ButtonStatus="true"
            link="/videos"
            arrowStatus={true}
            background="#0B132B"
          />
        </Grid>

        {videosData &&
          videosData.map((item, index) => {
            if (index === 0) {
              return (
                <Grid item xs={12} md={12}>
                  <div className="mainVideoSection">
                    <iframe
                      className="VideoIframe"
                      src={item.link}
                      title={item.title}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                    <h1>{item.title}</h1>
                  </div>
                </Grid>
              );
            }
          })}

        {videosData &&
          videosData.map((item, i) => {
            if (i > 0) {
              let videoId = getYouTubeID(item.img);
              videoArray.push(videoId);
              let videoThumb = `https://i.ytimg.com/vi/${videoId}/default.jpg`;
              return (
                <Grid item xs={12} md={6}>
                  <div onClick={() => playVideo(i)}>
                    <VideoCardOne
                      img={videoThumb}
                      title={item.title}
                      ImgSpace="3px"
                      titleColor="#0A2540"
                      border="underline"
                      icon={<PlayCircleOutlineRoundedIcon />}
                      ImgHeight="175px"
                      onClick={(i) => {
                        playVideo(i);
                      }}
                    />
                  </div>
                </Grid>
              );
            }
          })}
        <div>
          <VideoPlayerModal
            data={index}
            vidoeID={videoArray}
            isOpen={isOpen}
            onClose={() => setOpen(false)}
          />
        </div>
      </Grid>
    </div>
  );
}

export default VideosHome;
