import React from "react";
import { Section, SliderFive } from "master-react-lib";
import bannerImg from "../assets/imgs/banner.png";

function Banner() {
  const bannerData = [
    {
      imgPath: bannerImg,
      label: "Being a “Good Person” is Not Enough: Why Ethics Need Islam",
      readmore: "READ MORE",
      link: "#",
      target: "blank",
    },
    {
      imgPath: bannerImg,
      label:
        "New Largest Islamic Center with 68,000 Sq. Ft in the heart of NYC and need your generous support.",
      readmore: "READ MORE",
      link: "#",
      target: "new",
    },
  ];

  return (
    // <SliderFive
    //   bannerData={bannerData}
    //   background="#000"
    //   Buttonbackground="#56B149"
    //   ButtonRadius="0"
    //   SliderHieght="431px"
    // />
    <SliderFive
      tutorialSteps={bannerData}
      background="#000"
      SliderHieght="400px"
      ContentWidth="700px"
      BannerLabel="Read More"
      buttonColor="#FF6200"
      buttonLabelColor="#fff"
      ButtonRadius="0px"
      arrow={true}
    />
  );
}

export default Banner;
