import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import VideosHome from "../components/VideosHome";
import OurServices from "../components/OurServices";
import Grid from "@material-ui/core/Grid";
import UpcomingEvents from "../components/UpcomingEvents";
import SideBar from "../components/SideBar";
import { Section } from "master-react-lib";

function Home() {
  return (
    <div className="msjHContainer">
      <Header />
      <div className="InnerPageBody">
      <Section
        background="#fff"
        //ContainerWidth=""
        Spacing="40px"
        Container={
          <div className="msjHBody">
            <Grid container spacing={3}>
              <Grid item xs={12} md={9}>
                <Banner />
                <UpcomingEvents />
                <VideosHome />
              </Grid>

              <Grid item xs={12} md={3}>
                <SideBar />
              </Grid>
            </Grid>

            <OurServices />
          </div>
        }
      />
</div>
      <Footer />
    </div>
  );
}

export default Home;
