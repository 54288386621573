import React from 'react';
import { TitleTwo } from "master-react-lib";
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';

function Title(props) {

    return (

        <TitleTwo

            Title={props.Title}
            TitleColor="#FFFFFF"

            background={props.background}

            Button={props.ButtonStatus}
            buttonLebel={props.ButtonLabel}
            Link={props.link}
            //target="blank"
            arrowIcon={<ArrowForwardRoundedIcon />}
            arrowStatus={props.arrowStatus}
            arrowColor="#0B132B"

            buttoncolor="none"
            width="auto"
            height="30px"
            border="#0053A5 0px solid"
            borderRadius="4px"
            colorlabel="#0B132B"
            fontWeight="600"
            textAlign="center"

        />
    );
}


export default Title;