import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import OurServices from "../components/OurServices";
import Grid from "@material-ui/core/Grid";
import {
  Section,
  PageTitleTwo,
  SocialMediaOne,
  UserAvatarOne,
} from "master-react-lib";
import Button from "@material-ui/core/Button";

import facebook from "../assets/imgs/socialicons/Facebook-icon.svg";
import twitter from "../assets/imgs/socialicons/Twitter-icon.svg";
import youtube from "../assets/imgs/socialicons/YouTube-icon.svg";
import whatsapp from "../assets/imgs/socialicons/Whatsapp-icon.svg";

import Title from "../components/Title";

import user_1 from "../assets/imgs/user_1.png";
import user_2 from "../assets/imgs/user_2.png";

import Poster from "../assets/imgs/poster.jpg";

import Time from "../assets/imgs/time-icon.svg";
import Price from "../assets/imgs/money-icon.svg";
import Food from "../assets/imgs/food-icon.svg";
import User from "../assets/imgs/user-icon.svg";

import Location from "../assets/imgs/location-icon.svg";
import Directions from "../assets/imgs/directions-icon.svg";

import Zoom from "../assets/imgs/zoom-icon.svg";
import facebookIcon from "../assets/imgs/facebook-icon-blue.png";
import youtubeIcon from "../assets/imgs/youtube-icons.png";
import { getSingleEvent } from "../components/Functions";

import phoneIcon from "../assets/imgs/phone-icon-green.png";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import moment from "moment";
const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function EventDetails() {
  const [mainString, setMainString] = useState("");
  const [eventData, setEventData] = useState({
    description: [
      {
        children: [
          {
            text: "",
          },
        ],
      },
    ],
  });
  const guestCardOneData = [];
  const eventCardOneData = [
    {
      img: eventData?.imageDetails?.imageUrl,
      title: eventData?.title,
      // date: eventData?.createdAt,
      date: mainString,
      // location: eventData?.location?.address?.line1 + eventData?.location?.address?.line2 + eventData?.location?.address?.city + eventData?.location?.address?.state,
      location: `${eventData?.location?.address.line1} ${eventData.location?.address?.line2}, ${eventData?.location?.address?.city}`,
      amount: eventData?.entry?.fee,
      food: eventData?.refreshment?.providedList.join(", "),
      join: eventData?.entry?.entryType,
      description: eventData?.description[0].children[0].text,
      Poster: eventData?.imageDetails?.imageUrl,
      longitute: eventData?.location?.long,
      latitute: eventData?.location?.lat,
    },
  ];
  const [SpeakerData, setSpeakerData] = useState([]);
  const [date, setDate] = useState("");
  useEffect(async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const term = queryParams.get("q");
    let news = await getSingleEvent(orgId, term);

    if (news && news.data.body && news.data.body.data) {
      news.data.body.data.description = JSON.parse(
        news.data.body.data.description
      );
      setEventData(news.data.body.data);
      const time = news.data.body.data.eventStart.startAt.split("T");
      const timeZone = news.data.body.data.eventZone;

      const [year, month, day] = time[0].split("-");
      const Time = time[1].split(".")[0];
      const exactTime1 = Time.split(":");
      const DATE = new Date(time).toDateString();
      const TIME = new Date(time).toLocaleTimeString();

      // const startTime = new Date(
      //   `${month}/${day}/${year} ${+exactTime1[0] + 5}:${exactTime1[1]}:${
      //     exactTime1[2]
      //   }`
      // )
      //   .toLocaleTimeString()
      //   .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
      const startTime = moment(news.data.body.data.eventStart.startAt).format(
        "LT"
      );
      const endTime = moment(news.data.body.data.endAt).format("LT");

      const time2 = news.data.body.data.endAt.split("T");
      const [Year, Month, Day] = time2[0].split("-");
      const Time2 = time2[1].split(".")[0];
      const exactTime2 = Time2.split(":");
      const DATE2 = new Date(time2).toDateString();
      const TIME2 = new Date(time2).toLocaleTimeString();
      const timeMainString = `${DATE}, ${TIME} - ${DATE2}, ${TIME2} ${timeZone}`;
      setMainString(timeMainString);
      console.log(timeMainString);
      // const endTime = new Date(
      //   `${Month}/${Day}/${Year} ${+exactTime2[0] + 5}:${exactTime2[1]}:${
      //     exactTime2[2]
      //   }`
      // )
      //   .toLocaleTimeString()
      //   .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
      setDate(`${startTime} - ${endTime} ${timeZone}`);
      if (news.data.body.data && news.data.body.data.speakers) {
        news.data.body.data.speakers.map((element) => {
          let obj = {
            img: element?.imageMetaData?.imageUrl,
            name: element?.name.firstName + " " + element.name?.lastName,
            Designation: "",
          };
          guestCardOneData.push(obj);
        });
        setSpeakerData(guestCardOneData);
        // setGuard(obj);
      }
    }
  }, []);
  const [speakerData, setGuard] = useState({
    img: "https://d2imgltiwla1iy.cloudfront.net/270ba191d372c64c70c1ac9f47365a4d-Darul Jannah/164441728351879b2aafd-d3fc-492b-9993-c057c00de712.jpeg",
  });

  const breadcrumbsData = [
    // { page: "", paht: "/" },
  ];

  const socialIconData = [
    { icon: facebook, link: "" },
    { icon: youtube, link: "" },
    { icon: twitter, link: "" },
    { icon: whatsapp, link: "" },
  ];

  return (
    <div className="msjHContainer">
      <Header />
      <div className="InnerPageBody">
      <PageTitleTwo
        Title={
          <div className="PageTitlemainCtn">
            <div>
              <a href="/events">
                <ArrowBackRoundedIcon /> All Events
              </a>
            </div>
            {eventCardOneData[0].title}
          </div>
        }
        color="#fff"
        backgroundcolor="#029930"
        height="auto"
        breadcrumbsData={breadcrumbsData}
        currentPage={eventCardOneData[0].date}
      />

      <Section
        background="#fff"
        //ContainerWidth=""
        Spacing="40px"
        Container={
          <div className="msjHBody vrTabManCtn">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Title
                      Title="DESCRIPTION"
                      ButtonStatus="false"
                      arrowStatus={false}
                      background="#029930"
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <p>{eventCardOneData[0].description}</p>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Title
                      Title="SHARE ON SOCIAL"
                      ButtonStatus="false"
                      arrowStatus={false}
                      background="#029930"
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <SocialMediaOne
                      copyBackground="#029930"
                      copyColor="#fff"
                      socialIconData={socialIconData}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Title
                      Title="SPEAKERS"
                      ButtonStatus="false"
                      arrowStatus={false}
                      background="#029930"
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <UserAvatarOne
                      guestCardOneData={SpeakerData}
                      col="6"
                      spacing="2"
                      width=""
                      height="250px"
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Title
                      Title="EVENT POSTER"
                      ButtonStatus="false"
                      arrowStatus={false}
                      background="#029930"
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <div className="PosterCtn">
                      <img src={eventCardOneData[0].Poster} />
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Title
                      Title="EVENT DETAILS"
                      ButtonStatus="false"
                      arrowStatus={false}
                      background="#029930"
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <div className="InfoCard">
                      <li>
                        <img src={Time} alt="Time" /> <label>{date}</label>
                      </li>
                      <li>
                        <img src={Price} alt="Price" />{" "}
                        <label>{eventCardOneData[0].amount}</label>
                      </li>
                      <li>
                        <img src={Food} alt="Food" />{" "}
                        <label>{eventCardOneData[0].food}</label>
                      </li>
                      <li>
                        <img src={User} alt="user" />{" "}
                        <label>{eventCardOneData[0].join}</label>
                      </li>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <div className="InfoCard">
                      <li className="IconCtn">
                        <img src={Location} alt="Time" />
                        <div className="LocationInfo">
                          <h4>Location/Venue</h4>
                          <p>{eventCardOneData[0].location}</p>
                          <a
                            href={`https://maps.google.com?q=${eventCardOneData[0].latitute},${eventCardOneData[0].longitute}`}
                            target="_blank"
                          >
                            Get Directions <img src={Directions} alt="Time" />
                          </a>
                        </div>
                      </li>
                    </div>
                  </Grid>

                  {/* <Grid item xs={12} md={12}>
                    <div className="InfoCard">
                      <li className="IconCtn SocialCardCtn">
                        <div className="IconCtn SocialCarInfo">
                          <img src={Zoom} alt="Time" />
                          <div className="LocationInfo">
                            <h4>Zoom</h4>
                            <p>Show Details</p>
                          </div>
                        </div>
                        <Button variant="contained" disabled>
                          Join Live
                        </Button>
                      </li>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <div className="InfoCard">
                      <li className="IconCtn SocialCardCtn">
                        <div className="IconCtn SocialCarInfo">
                          <img src={facebookIcon} alt="Time" />
                          <div className="LocationInfo">
                            <h4>Facebook</h4>
                            <p>Show Details</p>
                          </div>
                        </div>
                        <Button variant="contained" disabled>
                          Join Live
                        </Button>
                      </li>
                    </div>
                    <div className="SocialLinkCtn">
                      <label>Link: </label>
                      <a href="https://www.facebook.com/orgpagenamecanbehere/videos/428058488246637">
                        https://www.facebook.com/orgpagenamecanbehere/videos/428058488246637
                      </a>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <div className="InfoCard">
                      <li className="IconCtn SocialCardCtn">
                        <div className="IconCtn SocialCarInfo">
                          <img src={youtubeIcon} alt="Time" />
                          <div className="LocationInfo">
                            <h4>YouTube</h4>
                            <p>Show Details</p>
                          </div>
                        </div>
                        <Button variant="contained" disabled>
                          Join Live
                        </Button>
                      </li>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <div className="InfoCard">
                      <li className="IconCtn">
                        <img src={phoneIcon} alt="Time" />
                        <div className="LocationInfo">
                          <h4>Teleconference</h4>
                          <p>
                            Dial in number:{" "}
                            <a href="tel:(555) 555-1234" target="_blank">
                              (555) 555-1234
                            </a>
                          </p>
                          <p>Passcode: 12345</p>
                        </div>
                      </li>
                    </div>
                  </Grid> */}
                </Grid>
              </Grid>
            </Grid>

            <OurServices />
          </div>
        }
      />
</div>
      <Footer />
    </div>
  );
}

export default EventDetails;
