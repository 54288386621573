import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import OurServices from "../components/OurServices";
import Grid from "@material-ui/core/Grid";
import SideBar from "../components/SideBar";
import {
  Section,
  PageTitleTwo,
  AccordionThree,
  ButtonOne,
} from "master-react-lib";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";

function TermsOfService() {
  const breadcrumbsData = [{ page: "HOME", paht: "/" }];

  return (
    <div className="msjHContainer">
      <Header />
      <div className="InnerPageBody">
        <PageTitleTwo
          Title="TERMS OF SERVICE"
          color="#fff"
          backgroundcolor="#029930"
          height="auto"
          breadcrumbsData={breadcrumbsData}
          currentPage="TERMS OF SERVICE"
        />

        <Section
          background="#fff"
          //ContainerWidth=""
          Spacing="40px"
          Container={
            <div className="msjHBody">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <div className="TosMainCtn">
                    <h1>Terms of Service</h1>
                    <p className="TosDate">LAST UPDATED: APR 04, 2024</p>

                    <p>
                      Masjid Umme Hani offers products and services subject to
                      these Terms of Use ("Terms"). These Terms outline the
                      legal framework for utilizing our Site. By accessing the
                      Site, you affirm your agreement to these terms, confirming
                      that you possess the authority and capacity to enter into
                      this agreement. If you disagree with any provision, kindly
                      refrain from accessing or using the Site.
                    </p>

                    <p>
                      Please note that these terms (may) are subject to change,
                      so we encourage you to revisit them periodically for
                      updates.
                    </p>

                    <h2>ACCESS TO THE SITE</h2>

                    <p>
                      We grant you a non-transferable, non-exclusive, revocable,
                      and limited license to access the Site solely for
                      personal, noncommercial use, subject to these Terms.
                    </p>

                    <h2>CERTAIN RESTRICTIONS</h2>

                    <p>
                      Your rights under these Terms are subject to specific
                      restrictions outlined in the Creative Commons
                      Attribution-NonCommercial-ShareAlike 4.0 International
                      License. Any updates or additions to the Site's
                      functionality will be governed by these Terms. All
                      copyright and proprietary notices must be retained on
                      copies of the Site. We reserve the right to modify,
                      suspend, or terminate the Site without notice. You
                      acknowledge that we shall not be liable to you or any
                      third party for any such changes, interruptions, or
                      terminations.
                    </p>

                    <h2>INTELLECTUAL PROPERTY RIGHTS</h2>

                    <p>
                      Except for User Content you provide, all intellectual
                      property rights in the Site and its content are owned by
                      us or our suppliers. These Terms do not grant you any
                      rights, title, or interest in or to any intellectual
                      property rights, except for the limited access rights
                      specified.
                    </p>

                    <h2>OTHER USERS</h2>

                    <p>
                      Each user is responsible for their own User Content. We do
                      not control User Content and disclaim responsibility for
                      any User Content. We are not liable for any loss or damage
                      resulting from user interactions. In case of a dispute, we
                      are not obligated to intervene.
                    </p>

                    <p>
                      By using the site, you release and discharge us and our
                      affiliates from all disputes, claims, controversies,
                      demands, rights, obligations, liabilities, actions, and
                      causes of action.
                    </p>

                    <h2>Cookies and Web Beacons</h2>

                    <p>
                      Similar to other websites, we utilize 'cookies' to store
                      information, such as visitors' preferences and accessed
                      pages. This information is used to enhance user experience
                      by customizing webpage content based on the visitors'
                      browser type and other relevant information.
                    </p>

                    <h2>Limitation of Liability</h2>

                    <p>
                      To the maximum extent permitted by law, neither Masjid
                      Umme Hani nor our suppliers shall be liable to you or any
                      third party for any indirect, consequential, exemplary,
                      incidental, special, or punitive damages arising from or
                      in connection with these terms or your use of, or
                      inability to use the site, even if Masjid Umme Hani has
                      been advised of the possibility of such damages. You
                      acknowledge that your access to and use of the site are at
                      your sole discretion and risk, and you bear full
                      responsibility for any damage to your device or computer
                      system or loss of data resulting from such use.
                    </p>

                    <h2>Term and Termination</h2>

                    <p>
                      Except as provided in this section, these Terms shall
                      remain in full force and effect during your use of the
                      Site. We reserve the right to suspend or terminate your
                      access to the Site at our sole discretion, including for
                      any violations of these Terms. Upon termination of your
                      rights under these Terms, your Account and ability to
                      access and use the Site will cease immediately. You
                      understand that any termination of your Account may result
                      in the deletion of your User Content associated with your
                      Account from our active databases. Masjid Umme Hani shall
                      have no liability to you for any termination of your
                      rights under these Terms.
                    </p>

                    <h2>Dispute Resolution</h2>

                    <p>
                      In the event of any concerns or disputes about the
                      Service, you agree to first attempt to resolve the matter
                      informally by contacting Masjid Umme Hani.
                    </p>

                    <p>
                      If you are a consumer residing in the European Union, you
                      are entitled to the mandatory provisions of the law in
                      your country of residence.
                    </p>

                    <h2>Compliance with US Laws</h2>

                    <p>
                      By using our services, you confirm that you are not
                      located in a country subject to the United States
                      government embargo or identified as a "terrorist
                      supporting" country. Additionally, you assert that you are
                      not listed on any United States government roster of
                      prohibited or restricted parties.
                    </p>

                    <h2>Severability and Waiver</h2>

                    <p>
                      If any part of these Terms is found to be unenforceable or
                      invalid, it will be modified to comply with applicable law
                      while preserving the intent of the remaining provisions.
                      The failure to enforce any right or provision of these
                      Terms shall not be deemed a waiver of such right or
                      provision.
                    </p>

                    <h2>Communications</h2>

                    <p>
                      Your interactions with Masjid Umme Hani, including
                      communications on the site, emails, and notices, are
                      conducted electronically. By using our services, you
                      consent to receive communications from Masjid Umme Hani
                      electronically, acknowledging that they satisfy any legal
                      requirements. You may choose to subscribe or adjust your
                      preferences for receiving updates, newsletters, and
                      notifications. You also have the option to unsubscribe or
                      modify preferences at any time to customize your
                      interaction with Masjid Umme Hani.
                    </p>

                    <p>
                      Users opt in to receive messages and notifications from
                      Masjid Umme Hani by providing their contact information on
                      our website. By checking a box, users consent to receive
                      communications at the provided email address or phone
                      number. Our{" "}
                      <a href="https://www.masjidummehani.org/privacy-policy">
                        Privacy Policy
                      </a>{" "}
                      outlines the details of opting in and the use of provided
                      information. Users can opt out of receiving messages and
                      notifications at any time by following the instructions
                      provided in our{" "}
                      <a href="https://www.masjidummehani.org/privacy-policy">
                        Privacy Policy
                      </a>
                      .
                    </p>

                    <h3>Disclaimer</h3>
                    <p>
                      Masjid Umme Hani cannot guarantee that the site or any of
                      its content, services, or features will be free of errors
                      or interruptions, or that any defects will be promptly
                      corrected. The site and its content are provided on an
                      "as-is" and "as-available" basis, and all information
                      presented on the site is subject to change without prior
                      notice. Masjid Umme Hani expressly disclaims all
                      warranties, whether express or implied, including but not
                      limited to warranties of accuracy, non-infringement,
                      merchantability, and fitness for a particular purpose.
                      Masjid Umme Hani also disclaims any liability for the
                      actions, omissions, or conduct of any third parties in
                      connection with or related to your use of the site or any
                      services provided by Masjid Umme Hani. You assume full
                      responsibility for your use of the site and any linked
                      sites. Your sole recourse against Masjid Umme Hani for
                      dissatisfaction with the site or any of its content is to
                      cease using the site or such content. This limitation of
                      liability is an integral part of the agreement between you
                      and Masjid Umme Hani.
                    </p>

                    <h2>Indemnity</h2>
                    <p>
                      By agreeing to this provision, you agree to indemnify and
                      protect Masjid Umme Hani, including its officers,
                      directors, shareholders, predecessors, successors in
                      interest, employees, agents, and subsidiaries, from any
                      demands, losses, liabilities, claims, or expenses
                      (including attorneys’ fees) made against Masjid Umme Hani
                      by any third party as a result of or in connection with
                      your use of the Site. This means you accept responsibility
                      for covering the costs and expenses incurred by Masjid
                      Umme Hani in the event of legal action or claims arising
                      from your actions or use of the Site.
                    </p>

                    <h2>Violation of These Terms of Use</h2>
                    <p>
                      In the event of any violation of these Terms of Use,
                      Masjid Umme Hani reserves the right to take appropriate
                      action, including disclosing relevant information, such as
                      your identity, to aid investigations or address complaints
                      related to your use of the Site. This may be necessary to
                      identify, contact, or take legal action against any
                      individual causing harm or interference with Masjid Umme
                      Hani’s rights, property, or the rights and property of
                      other Site users, including Masjid Umme Hani’s customers.
                      Masjid Umme Hani will also comply with any legal
                      requirements or governmental requests for information
                      disclosure and may collaborate with other entities for
                      fraud protection purposes.
                    </p>

                    <p>
                      You acknowledge and agree that Masjid Umme Hani may retain
                      any communication or transmission exchanged between you
                      and Masjid Umme Hani through the Site. This data may be
                      disclosed if legally mandated or if Masjid Umme Hani deems
                      it reasonably necessary to comply with legal processes,
                      enforce these Terms of Use, respond to claims of data
                      rights violations, or safeguard the rights, property, or
                      personal safety of Masjid Umme Hani, its employees, Site
                      users, visitors, and the public. Masjid Umme Hani reserves
                      the sole discretion, without prior notice, to suspend or
                      terminate your access to the Site and prevent future
                      access if it determines a violation of these Terms of Use
                      or any associated agreements or guidelines. You
                      acknowledge that any such violation constitutes an unfair
                      business practice causing irreparable harm to Masjid Umme
                      Hani. Consequently, you consent to Masjid Umme Hani
                      seeking injunctive or equitable relief, along with any
                      other remedies available at law or in equity.
                    </p>

                    <p>
                      In the event that legal action is initiated by Masjid Umme
                      Hani against you due to a violation of these Terms of Use,
                      you agree to cover all reasonable attorneys’ fees and
                      costs incurred, in addition to any relief granted to
                      Masjid Umme Hani. You acknowledge that Masjid Umme Hani
                      will not be liable to you or any third party for the
                      termination of your access to the Site resulting from a
                      violation of these Terms of Use.
                    </p>

                    <h2>Governing Law; Dispute Resolution</h2>

                    <p>
                      All matters related to your access or use of the Site,
                      including disputes, shall be governed by the laws of the
                      United States, without considering conflicts of law
                      principles. By accessing or using the Site, you agree to
                      personal jurisdiction and venue in the state and waive any
                      objection to such jurisdiction or venue unless you are an
                      EU consumer, in which case you may assert your claim in
                      the courts of your country of residence. Claims arising
                      under these Terms of Use must be brought within one (1)
                      year of the cause of action, or they are considered
                      waived, except for claims under separate purchase terms
                      and conditions for goods and services. Recovery for
                      damages is limited to out-of-pocket expenses, with the
                      prevailing party entitled to costs and attorneys’ fees. In
                      the event of a dispute, the parties shall make reasonable
                      efforts to promptly and in good faith resolve it. It is
                      preferable to first attempt to resolve any disputes
                      internally with Masjid Umme Hani. If resolution cannot be
                      achieved within thirty (30) days, either party may submit
                      the dispute to mediation. Should mediation prove
                      unsuccessful, the parties are free to pursue any available
                      remedies under applicable law.
                    </p>

                    <h2>Refunds</h2>

                    <p>
                      Under Charity Commission rules, Masjid Umme Hani is
                      generally unable to refund donations unless the donation
                      has been made in error, such as selecting the wrong option
                      on the online giving platform, or if an administration
                      error has occurred during processing, such as an
                      incorrect/duplicate amount being deducted by Masjid Umme
                      Hani. In such cases, the donor should contact our offices
                      within 14 days at:
                    </p>

                    <p>
                      <strong>Email:</strong>{" "}
                      <a href="mailto:munacentersf@gmail.com">
                        munacentersf@gmail.com
                      </a>
                      <br />
                      <strong>Phone:</strong> +1 (786) 523-6013
                      <br />
                      <strong>Address:</strong> 18215 NW 18th Ave, Miami
                      Gardens, Florida 33056
                    </p>

                    <p>
                      Please reach out to us directly if you believe a refund is
                      warranted due to one of these reasons, and we will do our
                      best to assist you promptly.
                    </p>

                    <h2>Copyright/Trademark Information</h2>

                    <p>
                      Copyright © 2024. All rights reserved. All trademarks,
                      logos, and service marks displayed on the Site are the
                      property of Masjid Umme Hani. You are not authorized to
                      use these Marks without our prior written consent.
                    </p>

                    <h2>Contact Us</h2>

                    <p>
                      If you have any questions regarding these terms and
                      conditions, please reach out to us using the information
                      provided below.
                    </p>

                    <p>
                      <strong>Email:</strong>{" "}
                      <a href="mailto:munacentersf@gmail.com">
                        munacentersf@gmail.com
                      </a>
                      <br />
                      <strong>Phone:</strong> +1 (786) 523-6013
                      <br />
                      <strong>Address:</strong> 18215 NW 18th Ave, Miami
                      Gardens, Florida 33056
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
          }
        />
      </div>

      <Footer />
    </div>
  );
}

export default TermsOfService;
