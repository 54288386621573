import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import OurServices from "../components/OurServices";
import Grid from "@material-ui/core/Grid";
import { Section, PageTitleTwo, VerticalTabs } from "master-react-lib";

import AboutUs from "../components/AboutUs";
import OurMissionVision from "../components/OurMissionVision";
import BoardOfDirectors from "../components/BoardOfDirectors";
import ServicesForTheComunity from "../components/ServicesForTheComunity";
import ContactUs from "../components/ContactUs";
import LetsConnectForm from "../components/LetsConnect";

function About() {
  
  const breadcrumbsData = [{ page: "HOME", paht: "/" }];

  const tabItems = [
    {
      title: "About us",
      component: (
        <div>
          {" "}
          <AboutUs /> <OurMissionVision /> <BoardOfDirectors />{" "}
          <ServicesForTheComunity /> <LetsConnectForm />
        </div>
      ),
    },

    {
      title: "Our mission & vision",
      component: (
        <div id="ourmission">
          <OurMissionVision />
        </div>
      ),
    },
    {
      title: "Our services",
      component: (
        <div id="ourservices">
          <ServicesForTheComunity />
        </div>
      ),
    },
    {
      title: "Our teams",
      component: (
        <div id="photogallery">
          <BoardOfDirectors />
        </div>
      ),
    },
    // { title: "Contact us", component: <div><ContactUs /></div> },
    {
      title: "Contact us",
      component: (
        <div id="contactus">
          <LetsConnectForm />
          {/* <ContactUs /> */}
        </div>
      ),
    },
  ];

  return (
    <div className="msjHContainer AboutUsContainer">
      <Header />
      <div className="InnerPageBody">
      <PageTitleTwo
        Title="ABOUT US"
        color="#fff"
        backgroundcolor="#029930"
        height="auto"
        breadcrumbsData={breadcrumbsData}
        currentPage="ABOUT US"
      />

      <Section
        background="#fff"
        //ContainerWidth=""
        Spacing="40px"
        Container={
          <div className="msjHBody vrTabManCtn">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <VerticalTabs tabItems={tabItems} />
              </Grid>
            </Grid>

            
          </div>
        }
      />
      </div>

      <Footer />
    </div>
  );
}

export default About;
