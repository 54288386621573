import React, { useEffect, useState } from "react";
import { FooterFive, FooterBottomOne } from "master-react-lib";
import Logo from "../assets/imgs/logo.png";
import Logo_2 from "../assets/imgs/logo_2.png";
import VideoCam from "../assets/imgs/videocam.svg";
import location from "../assets/imgs/location.svg";
import call from "../assets/imgs/call.svg";
import email from "../assets/imgs/email.svg";
import Locationicon from "../assets/imgs/Locationicon.svg";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";

import facebook from "../assets/imgs/socialicons/Facebook-icon.svg";
import twitter from "../assets/imgs/socialicons/Twitter-icon.svg";
import youtube from "../assets/imgs/socialicons/YouTube-icon.svg";
import whatsapp from "../assets/imgs/socialicons/Whatsapp-icon.svg";

import Sunrise from "../assets/imgs/Sunrise.png";
import Sun from "../assets/imgs/Sun.png";
import Moon from "../assets/imgs/Moon.png";
import { getSalahs, getOrganization } from "./Functions";
import moment from "moment";
const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function Footer() {
  const getTimeStamp = (time) => {
    let timestamp = moment(time).format("x");
    return timestamp;
  };
  const [lastUpdated, setlastUpdated] = useState();
  const [tableBodyData, settableBodyData] = useState([]);
  useEffect(async () => {
    const resSalahGet = await getSalahs(orgId, "deenweb");

    const SalahData = resSalahGet?.data?.body?.data;

    const ActiveSalah = SalahData.filter((text) => text.status === "true");

    const resOrg = await getOrganization(orgId);
    const orgIntegate = resOrg.data?.body?.data?.enabledIntegrations;

    const salah = orgIntegate?.deenweb?.salahs?.selectedSalahs;

    const d1 = new Date();
    const d = Number(d1);
    const resData = salah.filter((item) => item.status === "Public"); //&& Number(new Date(item.publishFrom)) < d && Number(new Date(item.publishTo)) > d)

    const Ids = resData.length ? resData.map((t) => t.salah) : [];

    let updatedSalahObj = [];

    const SalahToDisplay = ActiveSalah.filter((text) => Ids.includes(text._id));

    let sortedSalah = SalahToDisplay?.filter((el) => el.order).sort((a, b) => {
      return a.order - b.order;
    });

    const lu = sortedSalah?.length
      ? sortedSalah.sort((a, b) => {
          return getTimeStamp(a.updatedAt) - getTimeStamp(b.updatedAt);
        })
      : [];

    let tempData = [];

    if (lu && Array.isArray(lu)) {
      setlastUpdated(lu?.[0]?.updatedAt);

      lu.map((element) => {
        let name = element?.salahName?.fullName?.split("(");

        name = name?.length ? name[0] : salah?.salahName.fullName;
        let salahTime = element?.salahStart?.iqamahTime
          ? moment(element?.salahStart?.iqamahTime).utcOffset(0).format("LT")
          : "";
        let object = {
          col1: name,
          col2: salahTime,
          icon: element.salahName.fullName.includes("Dawn") ? (
            <img src={Sunrise} />
          ) : element.salahName.fullName.includes("Sunset") ||
            element.salahName.fullName.includes("Night") ? (
            <img src={Moon} />
          ) : (
            <img src={Sun} />
          ),
        };
        tempData.push(object);
      });

      settableBodyData(tempData);
    }
  }, []);

  const footerFiveSocial = [
    { icon: facebook, link: "https://www.facebook.com/masjidhani/" },
    // { icon: youtube, link: "https://www.facebook.com/" },
    // { icon: twitter, link: "https://www.facebook.com/" },
    // { icon: whatsapp, link: "https://www.facebook.com/" },
  ];

  return (
    <div>
      <FooterFive
        background="#0B132B"
        color="#FFFFFF"
        footerFiveSocial={footerFiveSocial}
        ContainerMaxWidth="1175px"
        Titlebackground="#029930"
        //// ABOUT COL ////

        TitleAbout="ABOUT US"
        AboutTextOne="Masjid Umme Hani provides daily Salawat, weekly Jumu’ah and facilitates community activities to the muslims of Miami Gardens areas."
        AboutTextTwo=" Masjid Umme Hani is a 501(c)- Non-Profit Tax ID 16-1621062."
        //// CONTACT INFO COL ////

        TitleContactIfo="CONTACT INFO"
        address="18215 NW 18th Ave, Miami Gardens, Florida 33056"
        getDirectionLink="https://www.google.com/maps"
        getDirectiontarget="_new"
        phoneOne="+1 (786) 523-6013"
        //phoneTwo="929-340-0399"
        email="munacentersf@gmail.com"
        contactLink="/about-us/#contactus"
        //contactTarget="+1 (786) 523-6013"
        //// IQAMAH COL ////

        TitleIqamah="IQAMAH TIME"
        //// BOTTOM FOOTER COL ////

        dividerLine="#676C7B"
        TosLink="/terms-of-services"
        ppLink="/privacy-policy"
        allRightText="© 2022 MASJID UMME HANI. ALL RIGHT RESERVED"
        //// Button Style ////
        label="FOLLOW US ON FACEBOOK"
        link=""
        target="blank"
        path="/"
        arrowIcon={<ArrowForwardIosRoundedIcon />}
        arrowStatus={true}
        arrowColor="#fff"
        buttoncolor="#4267B2"
        width="100%"
        height="50px"
        border="#ccc 0px solid"
        borderRadius="0px"
        fontWeight="600"
        //textAlign="center"
        //disabled="disabled"

        FooterRowTwo={true}
        tableBodyData={tableBodyData}
        // SlatTablebottomText="Updated: March 11, 2021"
      />

      <FooterBottomOne
        bordercolor="#029930"
        Container="1170px"
        target="_blank"
        TermsLink="https://deenconnect.io/terms-policies"
        PrivacyLink="https://deenconnect.io/terms-policies#privacy-policy"
      />
    </div>
  );
}

export default Footer;
