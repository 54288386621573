import React from 'react';
import { Section, ButtonOne, SliderFive } from "master-react-lib";
import CTAOneComp from "./CTAOne";
import IqamaTime from "./IqamaTime";
import AdOne from './AdOne';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
function SideBar(props) {

    return (

        <div className='SidebarContainer'>

            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <ButtonOne
                        label="DONATE FOR ZAKAT"
                        link="https://give.deenfund.com/masjid-umme-hani"
                        target="_blank"
                        arrowIcon={<ArrowForwardIosRoundedIcon />}
                        arrowStatus={true}
                        arrowColor="#fff"
                        buttoncolor="#029930"
                        width="100%"
                        height="50px"
                        border="#ccc 0px solid"
                        borderRadius="0px"
                        color="#fff"
                        fontWeight="600"
                    />
                </Grid>


                <Grid item md={12} xs={12}>
                    <ButtonOne
                        label="DONATE FOR SADAQAH"
                        link="https://give.deenfund.com/masjid-umme-hani"
                        target="_blank"
                        arrowIcon={<ArrowForwardIosRoundedIcon />}
                        arrowStatus={true}
                        arrowColor="#fff"
                        buttoncolor="#029930"
                        width="100%"
                        height="50px"
                        border="#ccc 0px solid"
                        borderRadius="0px"
                        color="#fff"
                        fontWeight="600"
                    />
                </Grid>

                <Grid item md={12} xs={12}>
                    <CTAOneComp />
                </Grid>

                <Grid item md={12} xs={12}>
                    <div style={{paddingTop:"58px", }}>
                    <IqamaTime />
                    </div>
                </Grid>

                <Grid item md={12} xs={12}>
                    <AdOne />
                </Grid>

            </Grid>

        </div >

    );
}


export default SideBar;