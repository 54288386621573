import React from "react";
import { TitleOne } from "master-react-lib";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";

function SetionTitle(props) {
  const onClick = () => {
    console.log("Back Button Clicked");
  };

  return (
    <TitleOne
      TitleLight={props.TitleLight}
      TitleBold={props.TitleBold}
      TitleLightEnd={props.TitleLightEnd}
      lineColor="#0053A5"
      TitleBoldColor="#0053A5"
      Button={props.ButtonStatus}
      buttonLebel={props.buttonLebel}
      Link={props.Link}
      target={props.target}
      onClick={onClick}
      arrowIcon={<ArrowForwardIosRoundedIcon />}
      arrowStatus={true}
      arrowColor="#0B132B"
      width="auto"
      height="50px"
      border="#0053A5 1px solid"
      borderRadius="4px"
      colorlabel="#0B132B"
      fontWeight="600"
      textAlign="center"
    />
  );
}

export default SetionTitle;
