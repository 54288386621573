import React from "react";
import Title from "./Title";
import Grid from "@material-ui/core/Grid";

function OurMissionVision(props) {
  return (
    <div className="SectionContainer">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <div className="missionContainer">
            <h2>OUR MISSION</h2>
            <p>
              We are on a mission to create a community where everyone has
              complete access to all the Islamic resources and feels safe to
              worship in our Masjid, where you can connect with the Almighty and
              find the ultimate satisfaction in your faith. Masjid Umme Hani is
              a place of unity, peace, and love for all.
            </p>
          </div>

          <div className="missionContainer">
            <h2>OUR VISION</h2>
            <p>
              Our vision is to provide a strong support system and access to
              every aspect and valuable resource of Islam to benefit the
              community. We aim to help the community by providing access to
              Islamic resources, social events, and mentorship opportunities. A
              community where everyone can have equal access to resources and
              support regardless of their religion or background.
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default OurMissionVision;
