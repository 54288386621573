import React from "react";
import { CTAOne } from "master-react-lib";

function CTAOneComp() {
  return (
    <CTAOne
      backgroundColor="#FF6200"
      Title="Build a house of Allah, and Allah will build one for you in Jannah"
      SubTitle="Al-Tirmidhi"
      ButtonLebel="MAKE A DONATION"
      link="https://give.deenfund.com/masjid-umme-hani"
      target="_blank"
      minHeight="200px"
      Btnbackground="#fff"
      Btncolor="#FF6200"
      BtnPadding="18px 16px"
    />
  );
}

export default CTAOneComp;
