import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import OurServices from "../components/OurServices";
import Grid from "@material-ui/core/Grid";
import SideBar from "../components/SideBar";
import {
  Section,
  PageTitleTwo,
  AccordionThree,
  ButtonOne,
} from "master-react-lib";
import bannerImg from "../assets/imgs/banner.png";
import videoImg from "../assets/imgs/video.png";
import PlayCircleOutlineRoundedIcon from "@material-ui/icons/PlayCircleOutlineRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";

function Faq() {
  const [loadMore, setLoadMore] = useState(false);
  const breadcrumbsData = [{ page: "HOME", paht: "/" }];

  const [expanded, setExpanded] = React.useState("1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const faqData = [
    {
      id: "1",
      qus: "What is Islam?",
      ans: (
        <div>
          <p>
            <b>
              Islam is a faith of pure monotheism that builds on the messages of
              previous prophets sent to humankind throughout history.
            </b>
            <br /> <br /> Islam is a continuation of the same message preached
            by all of God’s prophets — ending with the final prophet, Muhammad ﷺ
            — calling people to believe and worship in the one, true God alone.
            It is the original religion of Adam, Abraham, Moses, and Jesus that
            clarifies the purpose of creation and guides us on how to live a
            Godly, righteous life.
            <br /> <br /> As the final message from God to humanity, Islam is a
            manifestation of the Divine covenant made before the creation of the
            world where every soul gathered to recognize God as their Lord. In
            the Islamic worldview, this knowledge is coded into our subconscious
            makeup. Through testifying one’s faith, we reaffirm our witness of
            God’s Lordship and commit to living out a life according to and in
            line with His will. The Qur’an, God’s final words revealed to
            Prophet Muhammad, and prophetic teachings (Sunnah) offer guidance on
            how to adhere to the message.
          </p>
        </div>
      ),
    },

    {
      id: "2",
      qus: "what does the Islam mean?",
      ans: "The word ‘Islam’ is an Arabic term encompassing the meanings of submission, surrender, and peace.",
    },
    {
      id: "3",
      qus: "What do Muslims believe?",
      ans: (
        <div>
          In Islam, faith is composed of six core articles of faith, commonly
          referred to as the pillars of faith.
          <br />
          They are:
          <br />
          Belief in God
          <br />
          Belief in angels
          <br />
          Belief in Divinely revealed books
          <br />
          Belief in the Messengers of God
          <br />
          Belief in the Day of Judgement
          <br />
          Belief in Divine Decree
        </div>
      ),
    },
    {
      id: "4",
      qus: "Who is Allah?",
      ans: (
        <div>
          <p>
            <b>Allah is simply the Arabic word for God.</b>
            <br /> <br /> Say [O Prophet], “He is Allah—One. God, the eternal.
            He has never had offspring, nor was He born. And there is none
            comparable to Him.”<i>(Qur’an 112: 1-4)</i>
            <br />
            <br />
            The word ‘Allah’ does not have a gender or a plural form, indicating
            God’s uniqueness from all creation and His absolute oneness. Allah
            is also the term for ‘God’ used by non-Muslim Arabic speakers such
            as Arab Christians.
            <br /> <br /> Muslims believe God is exalted above everything else
            in the universe, unrestricted by time and space, and distinct in His
            attributes. He is All-Powerful and All-Wise, with full control over
            the universe, as it was created by His will. As taught in the
            Qur’an, Allah is the Lord of all the Worlds; humanity’s Creator,
            Nourisher, and Sustainer, whom all should worship and love.
            <br /> <br /> The 99 Beautiful Names of God encapsulate the various
            divine attributes through which God has described Himself. Allah is
            not restricted to these names alone; rather, they are a means by
            which someone can begin to know Him. God, above all else in the
            Islamic worldview, is the Most Merciful and the Most Kind (these are
            the names most often repeated in the Qur’an and in daily prayers),
            the Most Loving, and the Source of all peace, reliance, and refuge.
            The gift of worship is from His bounty to keep mankind forever
            connected to Him.
            <br /> <br />
            <b>
              “He is God: there is no god other than Him. It is He who knows
              what is hidden as well as what is in the open, He is the Lord of
              Mercy, the Giver of Mercy. He is God: there is no god other than
              Him, the Controller, the Holy One, Source of Peace, Granter of
              Security, Guardian over all, the Almighty, the Compeller, the
              Truly Great; God is far above anything they consider to be His
              partner. He is God: the Creator, the Originator, the Shaper. The
              best names belong to Him. Everything in the heavens and earth
              glorifies Him: He is the Almighty, the Wise.” [Quran, 59:22-24]
            </b>
          </p>
        </div>
      ),
    },
    {
      id: "5",
      qus: "What is Qur'an?",
      ans: (
        <div>
          <p>
            The Qur’an is the speech of God revealed to Prophet Muhammad ﷺ
            <br /> <br /> through the Angel Gabriel (Jibril) over a period of 23
            years.
            <br /> <br />
            The Qur’an constitutes the final revealed message from God through
            the last prophet and messenger sent to the world.
            <br /> <br />
            The Qur’an is both miraculously preserved and inimitable. In other
            words, what we know as the Qur’an today is the exact same text that
            was revealed to Prophet Muhammad ﷺ. The Qur’an is also miraculous in
            that it is impossible for human beings to imitate, contains no
            errors or contradictions, accurately foretells future events,
            describes matters of the natural world unknown to human beings at
            the time of revelation, and contains benefit for all human beings.
          </p>
        </div>
      ),
    },
    {
      id: "6",
      qus: "Who is Prophet Muhammad (P.B.U.H)?",
      ans: (
        <div>
          <p>
            Muhammad ﷺ, the son of Abdullah, was the final prophet and messenger
            of God.
            <br /> <br /> He was born in 570 CE in Mecca, where he eventually
            received the final revealed book sent to humanity, the Qur’an, and
            began calling people to the religion of Islam.
            <br /> <br />
            Despite facing many challenges and being met with rejection and
            persecution during his life and prophetic mission, he unfailingly
            exhibited beautiful character, rose above enmity and insult, and
            accomplished a stunning array of achievements. He is the most
            beloved of all creation to God, and is the greatest of not only all
            of God’s prophets and messengers, but the entirety of creation.
            <br /> <br />
            Prophet Muhammad ﷺ is often described as the “founder of Islam,” but
            this is not exactly accurate. Islam did not begin with his prophetic
            mission. Instead, it is more accurate to say he was sent to confirm
            and restore the messages of previous prophets and scriptures in
            their original, uncorrupted forms. All of the messengers of God,
            from Adam to Jesus to Muhammad, conveyed the same fundamental
            message of monotheism and good works and are brothers in
            prophethood.
          </p>
        </div>
      ),
    },
    {
      id: "7",
      qus: "What are five pillars of Islam?",
      ans: (
        <div>
          <p>
            The five pillars of Islam are: the testimony of faith, prayer,
            zakat, fasting of Ramadan, and performing hajj.
            <br /> <br /> Islam can be imagined as a building supported by five
            pillars. Each of these pillars are necessary for the building of
            Islam to stand. Without them, the structure collapses. These pillars
            are: Shahada: Testifying that there is no god but Allah (God), and
            that Muhammad ﷺ is the final Messenger of God Salat: Praying five
            daily prayers Zakat: Donating an obligatory amount of charity Sawm:
            Fasting in Ramadan Hajj: Making the pilgrimage to the Ka’aba
            <br /> <br />
            Everything else in Islam is built upon these five foundational
            pillars.
          </p>
        </div>
      ),
    },
    {
      id: "8",
      qus: "What is Islamic testimony of faith (shahada)?",
      ans: (
        <div>
          <p>
            The shahada is to believe in the message of Islam and declare that:
            There is no god but Allah (God), and Muhammad ﷺ is the Messenger of
            God.
            <br /> <br /> This testimony of faith, known in Arabic as the
            shahada, encapsulates the religion of Islam. It must be affirmed in
            the heart and uttered by the tongue.
            <br /> <br />
            The shahada is a declaration of monotheism (tawhid) stating that
            there is absolutely no deity except for the one, true, creator: God.
            He has no partners in divinity, no children, and none are worthy of
            worship except Him.
            <br /> <br />
            It is also a statement of acceptance that Prophet Muhammad ﷺ is
            God’s final prophet and messenger.
          </p>
        </div>
      ),
    },
    {
      id: "9",
      qus: "What do Muslims pray daily?",
      ans: (
        <div>
          <p>
            Prayer (salah) forges and sustains an essential connection between a
            person and their Creator.
            <br /> <br />
            Muslims have been instructed by God to pray five times daily within
            prescribed times. The Qur’an informs us that the primary reason for
            our creation and existence is to worship God, and these required
            five daily prayers are, in essence, a foundational fulfillment of
            this existential purpose.
          </p>
        </div>
      ),
    },
    {
      id: "10",
      qus: "What is Zakat?",
      ans: (
        <div>
          <p>
            Zakat refers to an obligatory portion of wealth a Muslim must give
            annually to the poor and needy.
            <br /> <br /> Islamic law details the various types of wealth that
            are zakat-eligible, how it is calculated, and the specific
            categories of people eligible to receive it.
            <br /> <br />
            Zakat is frequently mentioned alongside prayer (salat) throughout
            the Qur’an and was a ubiquitous feature of all prophetic calls.
            Among the five pillars of Islam, zakat is unique in that, in
            addition to being an act of devotion to God, it plays an important
            social function. While things like prayer and fasting are, on their
            face, individual acts of ritual devotion, zakat directly benefits
            one’s community and, as an institution, wields immense economically
            and socially transformative power.
          </p>
        </div>
      ),
    },
    {
      id: "11",
      qus: "What is sadaqa?",
      ans: (
        <div>
          <p>
            <b>Sadaqa refers to any act of charity or giving. </b>
            <br />
            <br />
            Charity is one of the most highly emphasized acts and virtues in
            Islam and bears a great deal of spiritual and societal benefit. The
            widespread practice of Muslims establishing charitable endowments
            across Muslim societies is just one example of how this emphasis
            upon charitable giving manifests.
            <br />
            <br />
            While the default conception of sadaqa is often monetary in nature,
            Islam outlines an expansive understanding of charity that extends to
            many non-monetary acts of giving such as spreading joy, meeting
            people with a smile, or merely speaking a kind word.
            <br />
            <br />
            In fact, Prophet Muhammad ﷺ said that, “Every good deed is charity.
            Verily, it is a good deed to meet your brother with a cheerful face,
            and to pour what is left from your bucket into the vessel of your
            brother.”
          </p>
        </div>
      ),
    },
    {
      id: "12",
      qus: "What holidays do Muslims celebrate?",
      ans: (
        <div>
          <p>
            Muslims celebrate two major holidays:{" "}
            <i>Eid al-Fitr and Eid al-Adha.</i>
            <br />
            <br />
            Muslim days of celebration occur at the conclusion of seasons of
            worship. The day of Eid al-Fitr is celebrated at the end of Ramadan,
            while Eid al-Adha is celebrated at the conclusion of Hajj. These
            days are intended to be days of festivity and joy for Muslims, and
            are distinguished from other days since one is not allowed to fast
            on them.
            <br />
            <br />
            Fridays are also weekly holy days for Muslims, as Prophet Muhammad ﷺ
            said, “Allah has made this day of Friday a celebration for the
            Muslims.”
          </p>
        </div>
      ),
    },
    {
      id: "13",
      qus: "What is Ramadan?",
      ans: (
        <div>
          <p>
            <b>
              Ramadan is the 9th month of the Hijri, or Islamic lunar calendar,
              during which Muslims are obligated to fast each day of the entire
              month.{" "}
            </b>
            <br />
            <br />
            Fasting entails abstaining from food, drink, and intimacy from
            sunrise to sunset. All sane, mature Muslims are obligated to fast
            unless they have a legitimate excuse (due to travel, illness, etc.),
            in which case Islamic law details if and how they are to make up for
            missed Ramadan fasts.
            <br />
            <br />
            The month of Ramadan is also the month in which the revelation of
            God’s final message to humanity, the Qur’an, began. The Qur’an was
            first revealed on the Night of Decree (laylat al-qadr), which is
            described as the greatest night of the entire year.
          </p>
        </div>
      ),
    },
    {
      id: "14",
      qus: "Why do Muslims have to fast for a full month?",
      ans: (
        <div>
          <p>
            <b>
              Muslims fast for the entire month because God prescribed this to
              be the length of the fasting period.
            </b>
            <br />
            <br />
            During prayer five times a day, Muslims face the Ka’aba in Mecca.
            Rituals like this were prescribed by God and, thus, we follow them.
            Likewise, Muslims fast for the entire month because God prescribed
            that this be the length of the fast:
            <br />
            <br />
            <i>
              Ramadan is the month in which the Quran was revealed as a guide
              for humanity, with clear proofs of guidance and the standard ˹to
              distinguish between right and wrong˺. So whoever is present this
              month, let them fast…
            </i>
            <span>[Qur’an, 2:185]</span>
            <br />
            <br />
            The aim and purpose of fasting is to develop a greater sense of
            consciousness of God. Fasting also teaches Muslims self-restraint,
            and enables people to experience hunger and, hopefully, a greater
            sense of empathy for those who are less fortunate.
          </p>
        </div>
      ),
    },
    {
      id: "15",
      qus: "Do Muslims fast outside of Ramadan?",
      ans: (
        <div>
          <p>
            <b>
              Muslims can voluntarily fast outside of the month of Ramadan, but
              are not required to do so.
            </b>
            <br />
            <br />
            Fasting outside of Ramadan is an extremely virtuous and highly
            rewarded practice that Muslims are encouraged to do if they can.
            <br />
            <br />
            In fact, it was Prophet Muhammad’s ﷺ regular practice to fast on
            Mondays and Thursdays, three days at the middle of each lunar month,
            and a number of other days throughout the year.
          </p>
        </div>
      ),
    },
    {
      id: "16",
      qus: "What is the significance of the Hajj pilgrimage?",
      ans: (
        <div>
          <p>
            <b>Hajj is one of the five pillars of Islam.</b>
            <br />
            <br />
            All Muslims must perform the Hajj once in a lifetime, if they are
            physically and financially able to do so. Among other things, the
            pilgrimage is a commemoration of the legacy and sacrifices of
            Prophet Abraham (Ibrahim), peace be upon him, and his family.
            <br />
            <br />
            The rituals of Hajj are full of powerful symbolism and lessons, and
            experiencing them can bring about profound spiritual change.
          </p>
        </div>
      ),
    },
    {
      id: "17",
      qus: "Who is Jesus in Islam?",
      ans: (
        <div>
          <p>
            Jesus, the son of Mary, is considered a prophet and a messenger of
            God in Islam. Muslims believe that Jesus (peace be upon him) was not
            only one of God’s prophets and messengers, but that he was among an
            elite category among them, distinguished for their extraordinary
            resolve and determination. This category includes Noah, Abraham,
            Moses, and Prophet Muhammad ﷺ.
            <br />
            <br />
            Islam teaches that Jesus was born of a miraculous birth to the
            Virgin Mary (who is herself highly revered in Islam). He is the
            Messiah (al-Masih) and the Word and Spirit of God, who performed
            numerous miracles including healing the blind and lepers, feeding
            multitudes, and resurrecting the dead.
            <br />
            <br />
            Muslims also believe in the Second Coming, and hold that Jesus will
            return to Earth shortly before the Day of Judgment to kill the False
            Messiah (al-Masih al-Dajjal, the Antichrist). He will rule the world
            justly, and usher in an era of unprecedented peace and prosperity.
            <br />
            <br />
            Muslims further believe that Jesus enjoys a special connection and
            proximity to Prophet Muhammad ﷺ and his nation (i.e. Muslims): He
            will return to lead and pass away among Prophet Muhammad’s
            followers. Prophet Muhammad ﷺ also declared that he is “the nearest
            of people to Jesus,” that they both came with the same religion, and
            that, “there was no prophet [sent] between us.”
            <br />
            <br />
            Muslims reject, however, that Jesus was crucified, that he is the
            son of God, and that he is a god himself.
          </p>
        </div>
      ),
    },
    {
      id: "18",
      qus: "Do Muslims believe in the crucifixion of Jesus?",
      ans: (
        <div>
          <p>
            <b>
              No, Muslims do not believe that Jesus was crucified or killed.
            </b>
            <br />
            <br />
            While Muslims do believe that Jesus and his followers were
            persecuted by authorities in his time, and that Jesus suffered
            betrayal at the hands of some of his own people, Islam teaches that
            Jesus was miraculously saved from crucifixion and ascended to the
            heavens where he remains alive.
          </p>
        </div>
      ),
    },
    {
      id: "19",
      qus: "Do Muslims believe in the Holy Spirit?",
      ans: (
        <div>
          <p>
            <b>
              Muslims believe in the Holy Spirit (al-Ruh al-Qudus) with
              reference to the Angel Gabriel (Jibril) and not as a part of a
              trinitarian godhead.
            </b>
            <br />
            <br />
            It was through the Holy Spirit that Jesus was able to perform
            numerous miracles.
            <br />
            <br />
            However, Muslims do not believe that the Holy Spirit is a part of a
            trinitarian godhead, nor a separate god to be worshipped or prayed
            to. Rather, the Holy Spirit, Gabriel, is a creation of God and under
            His complete control.
          </p>
        </div>
      ),
    },
    {
      id: "20",
      qus: "Do Muslims believe in Heaven and Hell?",
      ans: (
        <div>
          <p>
            <b>
              Yes. Belief in Heaven and Hell are essential aspects of Islamic
              belief.
            </b>
            <br />
            <br />
            Islam teaches that, after death, all of humanity will eventually be
            resurrected on the Day of Judgement, judged according to their
            individual actions, and sent to Parardise or the Hellfire.
            <br />
            <br />
            Paradise is a place of eternal bliss, beauty, pleasure, and
            happiness, filled with delights that “no eye has ever seen, no ear
            has ever heard, and what no human mind has ever imagined.” It is a
            place free of any pain, suffering, sadness, or death.
            <br />
            <br />
            Hell is a terrifying place filled with pain, suffering, and misery.
            Some who enter it will eventually be taken out and placed in Heaven,
            while others will remain eternally.
          </p>
        </div>
      ),
    },
    {
      id: "21",
      qus: "Are there dietary restrictions in Islam?",
      ans: (
        <div>
          <p>
            <b>
              Yes. Muslims are prohibited from consuming pork, alcohol, and
              substances that impair the mind, as well as those that do not meet
              proper standards (detailed below).
            </b>
            <br />
            <br />
            While an exhaustive treatment of Islamic dietary rules is beyond our
            scope here, it suffices to say that Islam’s prohibition regarding
            food, drink, and substances extend beyond merely pork and alcohol.
            <br />
            <br />
            For instance, Muslims are not permitted to use or ingest any type of
            intoxicating substance for merely recreational purposes.
            Additionally, certain types of animals (such as animals of prey) are
            not permitted for consumption. Even animals whose meat is
            permissible, if not slaughtered properly, becomes impermissible to
            consume.
          </p>
        </div>
      ),
    },
    {
      id: "22",
      qus: "What does halal mean?",
      ans: (
        <div>
          <p>
            <b>Halal refers to that which is permissible to Muslims.</b>
            <br />
            <br />
            Halal means what is allowed, in contrast to what is referred to as
            haram, not allowed for Muslims. However, it is commonly used to
            refer to foods (especially meat) that Muslims are permitted to
            consume.
          </p>
        </div>
      ),
    },
    {
      id: "23",
      qus: "Are kosher and halal the same?",
      ans: (
        <div>
          <p>
            <b>
              There are many similarities between what is kosher (permissible
              for Jews to consume) and what is halal (permissible for Muslims to
              consume), but there are also significant differences.
            </b>
            <br />
            <br />
            The two dietary codes diverge on issues such as alcohol and food
            pairings. Kosher laws generally do not prohibit alcohol, whereas
            Islamic law strictly prohibits its consumption, even when used for
            cooking. Unlike Jews, Muslims do not require the separation of dairy
            and meat products.
            <br />
            <br />
            However, both Islamic and Jewish dietary rules prohibit consuming
            pork and pork-derived products. They require similar ritual
            slaughtering procedures as well as the draining of the slaughtered
            animal’s blood, and both prohibit carrion (an animal found dead
            before slaughter). Many of the animals Muslims are allowed to
            slaughter are also permitted for Jews.
          </p>
        </div>
      ),
    },
    {
      id: "24",
      qus: "Is divorce allowed in Islam?",
      ans: (
        <div>
          <p>
            <b>Divorce is allowed in Islam.</b>
            <br />
            <br />
            While reconciliation between spouses is highly encouraged, and
            divorce has been described by the Prophet ﷺ as being, “The most
            detestable of permissible things to God,” it is nonetheless allowed
            according to Islamic law.
            <br />
            <br />A husband may unilaterally terminate a marriage through the
            process of talaq (legal divorce). In the case of talaq, women are
            entitled to financial support through the end of their waiting
            period as well as their stipulated deferred dower and a suitable
            gift (mut’ah). Should the couple have children, the man must also
            cover expenses of his children regardless of custody.
          </p>
        </div>
      ),
    },
  ];

  return (
    <div className="msjHContainer">
      <Header />
      <div className="InnerPageBody">
        <PageTitleTwo
          Title="FREQUENTLY ASKED QUESTIONS"
          color="#fff"
          backgroundcolor="#029930"
          height="auto"
          breadcrumbsData={breadcrumbsData}
          currentPage="FAQ"
        />

        <Section
          background="#fff"
          //ContainerWidth=""
          Spacing="40px"
          Container={
            <div className="msjHBody">
              <Grid container spacing={3}>
                <Grid item xs={12} md={9}>
                  <Grid container spacing={3}>
                    {!loadMore && (
                      <Grid item xs={12} md={12}>
                        {faqData.map((item, i) => {
                          return (
                            i <= 8 && (
                              <AccordionThree
                                title={item.qus}
                                panel={item.id}
                                expanded={expanded}
                                handleChange={handleChange}
                                ////// Styling ////////
                                iconStatus={false}
                                titleColor="#4d4d4d"
                                titleFontSize="16px"
                                TitleFontWeight="600"
                                AccordionBody={<p>{item.ans}</p>}
                              />
                            )
                          );
                        })}
                      </Grid>
                    )}
                    {loadMore && (
                      <Grid item xs={12} md={12}>
                        {faqData.map((item, i) => {
                          return (
                            <AccordionThree
                              title={item.qus}
                              panel={item.id}
                              expanded={expanded}
                              handleChange={handleChange}
                              ////// Styling ////////
                              iconStatus={false}
                              titleColor="#4d4d4d"
                              titleFontSize="16px"
                              TitleFontWeight="600"
                              AccordionBody={<p>{item.ans}</p>}
                            />
                          );
                        })}
                      </Grid>
                    )}

                    <Grid item xs={12} md={12}>
                      <div className="LoadMore">
                        {faqData.length > 9 && (
                          <ButtonOne
                            label="Load More Answer"
                            link=""
                            arrowIcon={<ArrowForwardIosRoundedIcon />}
                            arrowStatus={true}
                            arrowColor="#fff"
                            buttoncolor="#029930"
                            width="100%"
                            height="50px"
                            border="#ccc 0px solid"
                            borderRadius="4px"
                            color="#fff"
                            fontWeight="600"
                            textAlign="center"
                            onClick={() => {
                              setLoadMore(true);
                            }}
                          />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={3}>
                  <SideBar />
                </Grid>
              </Grid>

              <OurServices />
            </div>
          }
        />
      </div>
      <Footer />
    </div>
  );
}

export default Faq;
