import React from "react";
import axios from "axios";
import { withRouter } from "react-router";
import Title from "./Title";
import { RiArrowDropDownFill } from "react-icons/ri";

import {
  PhoneInputField,
  TextInputFixedLabel,
  SelectFieldOne,
  MultilineText,
  ButtonOne,
} from "master-react-lib";
import { getOrganization } from "./Functions";
import SendMessageAlert from "./SendMessageAlert";

import SetionTitle from "./SetionTitle";
import Grid from "@material-ui/core/Grid";
import LocationOnRoundedIcon from "@material-ui/icons/LocationOnRounded";
import CallRoundedIcon from "@material-ui/icons/CallRounded";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";

const TEST_SITE_KEY = process.env.REACT_APP_CAPTCHA_KEY;
const phoneRegex =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
class LetsConnectForm extends React.Component {
  constructor(props) {
    super(props);
    this.sectionRef = React.createRef();
    this.state = {
      inquiryTypes: [],
      captcha: "",
      phoneError: false,
      selectedInquiries: [],
      contactData: {
        firstName: "",
        lastName: "",
        email: "",
        inquiryType: "",
        message: "",
        stream: process.env.REACT_APP_CONTACT_STREAM,
        orgId: process.env.REACT_APP_ORGANIZATION_ID,
        section: "",
        phone: "",
      },
      showThankYou: false,
      open: false,
      alertMessage: false,
      selected: false,
      validation: {
        firstName: true,
        lastName: true,
        email: true,
        inquiryType: true,
        message: true,
      },
    };
  }

  async componentDidMount() {
    if (this.props.scrollTo === "connect-form") {
      this.sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }

    console.log("Org Data Called");
    const orgId = process.env.REACT_APP_ORGANIZATION_ID;
    try {
      const response = await getOrganization(orgId);
      const data = response.data.body.data;
      console.log("response: ", response);
      const pagesAll = data?.enabledIntegrations?.deenweb?.pages;
      const webContactInfo =
        data?.enabledIntegrations?.deenweb?.settings?.contactDetails ?? {};

      let inquiryTypesData = [];
      let a = data?.contactInfo?.forEach((contact) => {
        let b = contact?.inquiryTypes?.forEach((inq) => {
          inquiryTypesData.push(inq);
        });
      });

      const data2 = inquiryTypesData?.length
        ? inquiryTypesData?.map((el) => {
            return { id: el._id, primary: el.inquiryName, data: { ...el } };
          })
        : [];

      const Ids = webContactInfo?.inquiryTypes.length
        ? webContactInfo?.inquiryTypes.map((t) => t._id)
        : [];

      const selectedInq = data2.filter((inq) => Ids.includes(inq.id));

      this.setState({
        selectedInquiries: selectedInq,
      });
    } catch (err) {}
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${TEST_SITE_KEY}`;
    document.body.appendChild(script);
  }

  setPhone = (phone) => {
    this.setState((prevState) => ({
      contactData: {
        ...prevState.contactData,
        phone: phone,
      },
    }));
  };

  changeHandler = (e) => {
    console.log(e.target);
    console.log(e.target.value);
    // e.persist();
    if (
      (e.target.name === "firstName" && e.target.value.length > 3) ||
      (e.target.name === "lastName" && e.target.value.length > 3) ||
      (e.target.name === "message" && e.target.value.length > 3)
    ) {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          [e.target.name]: false,
        },
      }));
    } else {
      this.setState((prevState) => ({
        validation: {
          ...prevState.validation,
          [e.target.name]: false,
        },
      }));
    }
    this.setState((prevState) => ({
      contactData: {
        ...prevState.contactData,
        [e.target.name]: e.target.value,
      },
    }));
  };
  selectHandler = (e) => {
    this.setState({ selected: true });
    this.setState((prevState) => ({
      contactData: {
        ...prevState.contactData,
        inquiryType: e.target.value,
      },
    }));
  };
  submitHandler = (e) => {
    e.preventDefault();

    if (true) {
      let { firstName, lastName, message, inquiryType, email, phone } =
        this.state.contactData;
      if (phone.length > 3) {
        const validatePhone = phoneRegex.test(phone);
        if (!validatePhone) {
          this.setState({ phoneError: true });
        } else {
          this.setState({ phoneError: false });
        }
      } else {
        this.setState((prevState) => ({
          contactData: {
            ...prevState.contactData,
            phone: "",
          },
          phoneError: false,
        }));
      }
      this.validateEmail(email);
      if (
        firstName &&
        lastName &&
        message &&
        inquiryType &&
        email &&
        !this.state.phoneError
      ) {
        console.log("API CALLED....");
        axios
          .post(process.env.REACT_APP_BASEURL_DEV_API + "/contact", {
            ...this.state.contactData,
            name: {
              firstName,
              lastName,
            },
          })
          .then((res) => {
            this.setState({ alertMessage: true });
            this.setState({
              contactData: {
                firstName: "",
                lastName: "",
                email: "",
                inquiryType: "",
                message: "",
                stream: process.env.REACT_APP_CONTACT_STREAM,
                orgId: process.env.REACT_APP_ORGANIZATION_ID,
                section: "",
                phone: "",
              },
            });
            // this.props.showAlert(true, email);

            localStorage.setItem("contactMail", email);
            console.log("contact ", res);
          })
          .catch((err) => {
            console.error(err);
            this.setState({
              contactData: {
                firstName: "",
                lastName: "",
                email: "",
                inquiryType: "",
                message: "",
                stream: process.env.REACT_APP_CONTACT_STREAM,
                orgId: process.env.REACT_APP_ORGANIZATION_ID,
                section: "",
                phone: "",
              },
            });
          });
      }
    }
    window.location.reload(false);
  };

  // validateEmail(email) {
  //   const re =
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   console.log(
  //     "INSIDE THE VALIDATE IMAGE",
  //     re.test(String(email).toLowerCase())
  //   );
  //   return re.test(String(email).toLowerCase());
  // }
  validateEmail(email) {
    if (email.includes(".co") && email.includes("@") && email.length >= 6)
      return true;
    else return false;
  }

  render() {
    const inqTypes = this.state.selectedInquiries; //this.props.organization?.enabledIntegrations?.deenweb?.settings?.contactDetails?.inquiryTypes ?? []
    console.log("inqTypes: ", inqTypes);
    const { contactData } = this.state;
    const { firstName, lastName, message, inquiryType, email } = contactData;

    let disableBtn =
      firstName && lastName && message && inquiryType ? false : true;

    return (
      <>
        {/* <style>{`
        .text_area{
          border:1px solid ${
            this.state.validation.message ? "red" : "rgb(233 233 233)"
          } !important;
        }  
        .custom-select{
          border:1px solid ${
            this.state.validation.inquiryType ? "red" : "rgb(233 233 233)"
          } !important;
          
        }
          
          
          
          `}</style> */}
        <div className="SectionContainer" id="contactus">
          {this.state.alertMessage && <SendMessageAlert />}

          <Grid container spacing={2}>
            <Grid item xs={12} md={12} className="noBtn">
              <Title
                Title="CONTACT US"
                ButtonStatus="false"
                arrowStatus={false}
                background="#0B132B"
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <div className="ContactUsThree">
                <div className="contactForm">
                  {/* <div className="ContactUsTitle">{title}</div> */}
                  {/* <div className="ContactUsDecription">{description}</div> */}

                  <div className="contactFormContainer">
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <TextInputFixedLabel
                          value={contactData.firstName}
                          required={true}
                          onChange={(e) => this.changeHandler(e)}
                          label="First name"
                          name="firstName"
                          labelStatus={true}
                          HelpertextStatus={true}
                          placeholder="First name"
                          Errormessage="Error message"
                          errortextStatus={false}
                          // error={this.state.validation.firstName}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextInputFixedLabel
                          value={contactData.lastName}
                          onChange={(e) => this.changeHandler(e)}
                          required={true}
                          label="Last name"
                          name="lastName"
                          labelStatus={true}
                          HelpertextStatus={true}
                          placeholder="Last name"
                          Errormessage="Error message"
                          errortextStatus={false}
                          // error={this.state.validation.lastName}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextInputFixedLabel
                          onChange={(e) => this.changeHandler(e)}
                          value={contactData.email}
                          required={true}
                          label="Email"
                          name="email"
                          labelStatus={true}
                          HelpertextStatus={true}
                          placeholder="Email"
                          Errormessage="Error message"
                          errortextStatus={false}
                          // error={this.state.validation.email}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <PhoneInputField
                          phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                          country={"us"}
                          value={this.state?.contactData?.phone}
                          countryCodeEditable={false}
                          onChange={(phone, country) => this.setPhone(phone)}
                          onBlur={() => console.log("onBlur")}
                          onKeyDown={(e) =>
                            console.log("onKeyDown", e.target.value)
                          }
                          variant="outlined"
                          name="phone"
                          // onlyCountries={["us", "ca", "gb", "my"]}
                          containerStyle={{}}
                          buttonStyle={{}}
                          inputStyle={{
                            marginLeft: "0px",
                            height: "40px",
                            width: "100%",
                            fontFamily: "Roboto-Regular",
                            fontSize: "16px",
                          }}
                          containerClass={{}}
                          errorText={"Phone number is required"}
                          errorStyles={{ marginBottom: "5px" }}
                          autoFormat={true}
                          disableDropdown={false}
                          disabled={false}
                          disableCountryCode={false}
                          enableSearch={false}
                          disableSearchIcon={false}
                          placeholder={""}
                          inputProps={{}}
                          //specialLabel={'Primary Phone'}
                          // preferredCountries={[]}
                          // excludeCountries={[]}
                          labelFixed="Phone Number (Optional)"
                          LabelStatus={true}
                          // error={this.state.phoneError}
                        />
                      </Grid>

                      {/* <Grid item md={12} xs={12}>
                        <label className="Label">
                          inquiryType <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          className="browser-default custom-select "
                          // defaultValue={"select"}
                          name="Inquiry type"
                          onChange={(e) => this.changeHandler(e)}
                          value={contactData.inquiryType}
                        >
                          <option value="Inquiry Types">Inquiry Types</option>
                          {inqTypes?.map((el, i) => {
                            return (
                              <option key={i} value={el.data.inquiryName}>
                                {el.data.inquiryName}
                              </option>
                            );
                          })}
                        </select>
                      </Grid> */}
                      <Grid item md={12} xs={12}>
                        <div className="SelectDropField">
                        <SelectFieldOne
                          label="Inquiry type"
                          required={true}
                          name="Inquiry type"
                          placeholder="inquire"
                          selectdata={[
                            { value: 1, select: "Inquiry type" },
                            {
                              value: "General Queries",
                              select: "General Queries",
                            },
                            {
                              value: "Services/Activities",
                              select: "Services/Activities",
                            },
                            { value: "Masjid Tour", select: "Masjid Tour" },
                            { value: "Donations", select: "Donations" },
                            { value: "Volunteer", select: "Volunteer" },
                            { value: "Media/Press", select: "Media/Press" },
                          ]}
                          defaultValue={1}
                          handleChange={(e) => this.selectHandler(e)}
                        />
                        <span
                          className="dropDownIcon"
                          onClick={(e) => this.selectHandler(e)}
                        >
                          <RiArrowDropDownFill />
                        </span>
                        </div>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <div className="ContactfieldCtn">
                          <label className="Label">
                            Your message <span style={{ color: "red" }}>*</span>
                          </label>
                          <textarea
                            className="text_area form-control"
                            maxLength="300"
                            minLength="40"
                            required
                            placeholder="Enter your message here..."
                            name="message"
                            onChange={(e) => this.changeHandler(e)}
                            value={contactData.message}
                          ></textarea>
                          <p className="text_limit_hint">
                            CHAR {contactData?.message?.length ?? 0}/300
                          </p>
                        </div>
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <ButtonOne
                          label="Send Message"
                          onClick={this.submitHandler}
                          arrow={false}
                          arrowColor="#fff"
                          addIcon={false}
                          AddIconColor="#0053A5"
                          buttoncolor="#56B149"
                          width="100%"
                          height="50px"
                          borderRadius="4px"
                          color="#fff"
                          fontWeight="600"
                          textAlign="center"
                          disabled={
                            firstName.length >= 1 &&
                            lastName.length >= 1 &&
                            message.length >= 1 &&
                            this.state.selected &&
                            email.includes(".co") &&
                            email.includes("@") &&
                            email.length >= 6
                              ? false
                              : true
                          }
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

// export default withRouter(LetsConnectForm);
export default LetsConnectForm;
