import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import OurServices from "../components/OurServices";
import Grid from "@material-ui/core/Grid";
import SideBar from "../components/SideBar";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";

import {
  Section,
  PageTitleTwo,
  CardSix,
  ButtonOne,
  TabOne,
  NoAvailableEvents,
} from "master-react-lib";
import bannerImg from "../assets/imgs/banner.png";
import videoImg from "../assets/imgs/video.png";
import PlayCircleOutlineRoundedIcon from "@material-ui/icons/PlayCircleOutlineRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { getEvents } from "../components/Functions";
import moment from "moment";
const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function Events() {
  const [eventData, setEventData] = useState([]);
  const [showNoEvent, setShowNoEvent] = useState(true);

  const [showMoreEvents, setShowMoreEvents] = useState(false);

  useEffect(async () => {
    const events = await getEvents(orgId, "deenweb");
    let temp = [];

    if (events.data.body.data) {
      events.data.body.data.map((element) => {
        let time = element.event.eventStart.startAt.split("T");
        const timeZone = element.event.eventZone;

        const [year, month, day] = time[0].split("-");
        const Time = time[1].split(".")[0];
        const [DAY, DATE, MONTH] = new Date(year, month - 1, day)
          .toUTCString()
          .split(" ");

        const ExactTime = Time.split(":");

        const newTime = moment(element.event.eventStart.startAt).format("LT");
        // const newTime = new Date(
        //   `${month}/${day}/${year} ${+ExactTime[0] + 5}:${ExactTime[1]}:${
        //     ExactTime[2]
        //   }`
        // )
        //   .toLocaleTimeString()
        //   .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
        const dataString = `${DAY} ${MONTH} ${day} AT ${newTime} ${timeZone}`;

        let obj = {
          img: element.event.imageDetails.imageUrl,
          link: "/events-details?q=" + element?.event?._id,
          DateTime: dataString,
          title: element.event.title,
          order: element.event.order,
          eventStatus: element.event.published ? "Online" : "Offline",
        };
        element.status === "Public" && temp.push(obj) && setShowNoEvent(false);
      });
      setEventData(temp);
    }
  }, []);

  const breadcrumbsData = [{ page: "HOME", paht: "/" }];

  const tabItems = [
    {
      title: "UPCOMING EVENTS",
      body: showNoEvent ? (
        <Grid item xs={12} md={12}>
          <NoAvailableEvents
            Title="Oops! No available events"
            Description="In the meantime, our secured donation form is superbly ready as we continuously need your generous donations. Please click on the button below to support us."
            background="#fff"
            //// Button Style ////
            buttonLebel="I want to donate"
            link="https://give.deenfund.com/masjid-umme-hani"
            target="_blank"
            arrowIcon={<ArrowForwardRoundedIcon />}
            arrowStatus={true}
            arrowColor="#fff"
            buttoncolor="#029930"
            width="200px"
            height="50px"
            border="#ccc 1px solid"
            borderRadius="4px"
            colorlabel="#fff"
            fontWeight="600"
            textAlign="center"
          />
        </Grid>
      ) : (
        <div>
          <Grid container spacing={3}>
            {eventData &&
              eventData.map((item, i) => (
                <Grid item xs={12} md={4} sm={6}>
                  <div className="videocards">
                    <CardSix
                      img={item.img}
                      DateTime={item.DateTime}
                      link={item.link}
                      title={item.title}
                      eventStatus={item.eventStatus}
                      buttonLabel="Event Details"
                      btnColor="#029930"
                    />
                  </div>
                </Grid>
              ))}
          </Grid>
        </div>
      ),
    },

    {
      title: "PAST EVENTS",
      body: showNoEvent ? (
        <Grid item xs={12} md={12}>
          <NoAvailableEvents
            Title="Oops! No available events"
            Description="In the meantime, our secured donation form is superbly ready as we continuously need your generous donations. Please click on the button below to support us."
            background="#fff"
            //// Button Style ////
            buttonLebel="I want to donate"
            link="https://give.deenfund.com/masjid-umme-hani"
            target="_blank"
            arrowIcon={<ArrowForwardRoundedIcon />}
            arrowStatus={true}
            arrowColor="#fff"
            buttoncolor="#029930"
            width="200px"
            height="50px"
            border="#ccc 1px solid"
            borderRadius="4px"
            colorlabel="#fff"
            fontWeight="600"
            textAlign="center"
          />
        </Grid>
      ) : (
        <div>
          <Grid container spacing={3}>
            {eventData &&
              eventData.map((item, i) => (
                <Grid item xs={12} md={4} sm={6}>
                  <div className="videocards">
                    <CardSix
                      img={item.img}
                      DateTime={item.DateTime}
                      link={item.link}
                      title={item.title}
                      eventStatus={item.eventStatus}
                      buttonLabel="Event Details"
                      btnColor="#029930"
                    />
                  </div>
                </Grid>
              ))}
          </Grid>
        </div>
      ),
    },
  ];

  return (
    <div className="msjHContainer">
      <Header />
      <div className="InnerPageBody">
        <PageTitleTwo
          Title="EVENTS"
          color="#fff"
          backgroundcolor="#029930"
          height="auto"
          breadcrumbsData={breadcrumbsData}
          currentPage="EVENTS"
        />

        <Section
          background="#fff"
          //ContainerWidth=""
          Spacing="40px"
          Container={
            <div className="msjHBody">
              <Grid container spacing={3}>
                <Grid item xs={12} md={9}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <TabOne tabItems={tabItems} tabBody={true} />
                    </Grid>

                    {eventData.length > 9 && (
                      <Grid item xs={12} md={12}>
                        <div className="LoadMore">
                          <ButtonOne
                            label="Load More Events"
                            link=""
                            arrowIcon={<ArrowForwardIosRoundedIcon />}
                            arrowStatus={true}
                            arrowColor="#fff"
                            buttoncolor="#029930"
                            width="100%"
                            height="50px"
                            border="#ccc 0px solid"
                            borderRadius="4px"
                            color="#fff"
                            fontWeight="600"
                            textAlign="center"
                            onClick={() => setShowMoreEvents(true)}
                          />
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12} md={3}>
                  <SideBar />
                </Grid>
              </Grid>

              <OurServices />
            </div>
          }
        />
      </div>
      <Footer />
    </div>
  );
}

export default Events;
