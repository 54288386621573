import React from 'react';
import { UserAvatarOne } from "master-react-lib";
import Title from "./Title";
import Grid from '@material-ui/core/Grid';
import user_1 from '../assets/imgs/user_1.png';
import user_2 from '../assets/imgs/user_2.png';
import user_3 from '../assets/imgs/user_3.png';

function BoardOfDirectors(props) {

    const guestCardOneData = [

        {
            img: user_1,
            name: "Imam Siraj Wahhaj",
            Designation: "Imam, Masjid At-Taqwa, NY",
        },
        {
            img:user_2,
            name: "Imam Siraj Wahhaj",
            Designation: "Imam, Masjid At-Taqwa, NY",
        },
        {
            img: user_3,
            name: "Imam Siraj Wahhaj",
            Designation: "Imam, Masjid At-Taqwa, NY",
        },

    ];

    return (

        <div className='SectionContainer'>

            <Grid container spacing={2}>
                <Grid item xs={12} md={12} className="noBtn">
                    <Title
                        Title="BOARD OF DIRECTORS"
                        ButtonStatus="false"
                        arrowStatus={false}
                        background="#0B132B"
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <UserAvatarOne
                        guestCardOneData={guestCardOneData}
                        col="4"
                        spacing="2"
                        width=""
                        height="250px"

                    />
                </Grid>
            </Grid>
        </div>
    );
}


export default BoardOfDirectors;