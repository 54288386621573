import React from "react";
import Title from "./Title";
import Grid from "@material-ui/core/Grid";
import bannerImg from "../assets/imgs/banner.png";

function AboutUs(props) {
  return (
    <div className="SectionContainer" id="about">
      <div className="AboutBanner">
        <img src={bannerImg} />
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12} className="noBtn">
          <Title
            Title="ABOUT US"
            ButtonStatus="false"
            arrowStatus={false}
            background="#0B132B"
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <p>
            Welcome to Masjid Umme Hani, a project of MUNA, aimed at creating a
            space to serve the needs of our community. Our organization is in
            the State of Florida, the land was purchased in 2017 and came into
            view in 2020.{" "}
          </p>
          <p>
            The purpose was to build a place of unity, peace, and love for all,
            regardless of their religion or background. It is a safe and
            welcoming environment for everyone. We understand the needs of our
            community, which is why we offer multiple services to meet your
            needs. From spiritual guidance, social support, educational
            resources, we are here to help.
          </p>
          <p>
            Masjid Umme Hani adheres to the teachings of the Quran and the
            traditions of Prophet Mohammed (Peace Be upon Him) in all its
            undertakings. The community is diverse, non-sectarian, and open to
            men and women of all ethnicities, races, and languages who agree to
            follow its rules, regulations, and procedures.
          </p>
          <p>
            Masjid Umme Hani also values and actively engages in civic and civil
            activities with other faith communities and society in general.
          </p>
        </Grid>
      </Grid>
    </div>
  );
}

export default AboutUs;
