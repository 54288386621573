import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import OurServices from "../components/OurServices";
import Grid from "@material-ui/core/Grid";
import SideBar from "../components/SideBar";
import {
  Section,
  PageTitleTwo,
  AccordionThree,
  ButtonOne,
} from "master-react-lib";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";

function Tos() {
  const breadcrumbsData = [{ page: "HOME", paht: "/" }];

  return (
    <div className="msjHContainer">
      <Header />
      <div className="InnerPageBody">
        <PageTitleTwo
          Title="PRIVACY POLICY"
          color="#fff"
          backgroundcolor="#029930"
          height="auto"
          breadcrumbsData={breadcrumbsData}
          currentPage="PRIVACY POLICY"
        />

        <Section
          background="#fff"
          //ContainerWidth=""
          Spacing="40px"
          Container={
            <div className="msjHBody">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <div className="TosMainCtn">
                    <h1>Privacy Policy</h1>
                    <p className="TosDate">LAST UPDATED: APR 04, 2024</p>

                    <p>
                      This notice outlines how Masjid Umme Hani ("we", "us")
                      collects, utilizes, and safeguards information concerning
                      you during your usage of our website and/or applications.
                      Prior to using our website or applications, please
                      carefully review this notice. We reserve the right to
                      modify this notice periodically without prior notice. Your
                      access and usage of our websites and applications imply
                      your consent to these practices as well as any other
                      applicable uses.
                    </p>

                    <h2>Data Collection</h2>
                    <p>
                      To improve the content and user experience of our website
                      and applications, Masjid Umme Hani may automatically
                      collect certain information from you, including:
                    </p>

                    <p>
                      ● Your Internet Protocol ("IP") address from your Internet
                      Service Provider ("ISP") or computer.
                      <br />● The Domain Name associated with your ISP or
                      computer.
                      <br /> ● The type and version of your operating system and
                      browser (e.g., Internet Explorer, Opera, Firefox, Chrome,
                      etc.).
                      <br /> ● The date, time, and duration of your visit.{" "}
                      <br /> ● Your interactions within the application, such as
                      clicks.
                    </p>

                    <p>
                      Additionally, Masjid Umme Hani may ask for and collect
                      information from you when you interact with our websites
                      and applications, such as details provided when creating
                      an account, submitting forms electronically, or
                      participating in surveys.
                    </p>

                    <h2>Use of Information and Privacy</h2>

                    <p>
                      We do not collect personally identifiable information for
                      commercial or marketing purposes, nor do we sell or trade
                      the personally identifiable information we gather. All
                      personally identifiable information collected is securely
                      stored.
                    </p>

                    <p>
                      The information we collect is used to maintain
                      compatibility and improve the services provided through
                      Masjid Umme Hani's applications and websites. It also
                      serves internal purposes, including:
                    </p>

                    <p>
                      ● Developing new features for our applications/websites.{" "}
                      <br />● Sending notifications, alerts, or updates about
                      events.
                      <br /> ● Responding to legal matters.
                      <br /> ● Sending technical notices, updates, security
                      alerts, and administrative messages.
                      <br /> ● Addressing user comments, inquiries, and customer
                      service requests.
                      <br /> ● Analyzing trends, usage patterns, and activities
                      associated with our services.
                      <br /> ● Personalizing and refining our services.
                      <br /> ● Ensuring compliance with educational purposes,
                      applicable laws, regulations, legal processes, or
                      governmental requests.
                    </p>

                    <p>
                      The disclosure of collected information about you is
                      subject to specific laws and regulations. Masjid Umme Hani
                      reserves the right to monitor and examine all content and
                      traffic on Masjid Umme Hani-provided networks and
                      applications, including traffic from devices both owned
                      and not owned by Masjid Umme Hani, if they are used to
                      access Masjid Umme Hani resources.
                    </p>

                    <h2>Data Security</h2>

                    <p>
                      We employ reasonable measures to safeguard our websites
                      and applications, as well as the information stored within
                      them, against loss, theft, misuse, unauthorized access,
                      disclosure, alteration, and destruction.
                    </p>

                    <p>
                      However, you are requested to take necessary security
                      measures. Failure to do so may result in security
                      vulnerabilities for which Masjid Umme Hani cannot be held
                      responsible.
                    </p>

                    <p>
                      Please note, we do not share or sell your information for
                      marketing purposes or any related activities.
                    </p>

                    <h2>Cookies</h2>
                    <p>
                      Cookies are files that allow an application or website to
                      distinguish between users. Masjid Umme Hani uses
                      "temporary cookies'' in certain areas of our sites and
                      applications, which expire when the browser session ends.
                      Additionally, we may use “persistent cookies'' to
                      understand how users interact with and access our sites
                      and applications.
                    </p>

                    <p>
                      Most browsers offer options to customize cookie settings,
                      such as rejecting or accepting cookies with a visual
                      prompt, or deleting cookies entirely. However, it's
                      important to note that certain features of our
                      applications and websites may rely on cookies for proper
                      functionality.
                    </p>

                    <h2>Web Beacons</h2>

                    <p>
                      At Masjid Umme Hani, we do not utilize “web beacons” on
                      our web pages or within emails for any purposes other
                      than:
                    </p>

                    <p>
                      ● Identifying site performance requirements.
                      <br /> ● Ensuring compatibility with the technology
                      utilized by site visitors.
                      <br /> ● Enhancing and expanding services provided through
                      our applications/website.
                    </p>

                    <h2>Communication</h2>

                    <p>
                      Users provide consent to receive messages by visiting our
                      app/website and completing a form with their details. They
                      agree to receive messages and notifications from Masjid
                      Umme Hani by checking a box, indicating their agreement to
                      receive communications via the provided phone number or
                      email. By providing your Personal Information, you may
                      explicitly opt-in to receive notifications from Masjid
                      Umme Hani.
                    </p>

                    <p>
                      We provide an option to unsubscribe from our
                      communications. Please note that transaction-related
                      emails regarding products or services you've requested
                      will continue even after unsubscribing. Certain
                      communications related to the operation and administration
                      of the Services cannot be opted out of, such as updates to
                      our Terms of Use or this Policy. If you no longer wish to
                      receive communications from us, you must terminate your
                      relationship with Masjid Umme Hani and cease using our
                      Services. To opt-out of Masjid Umme Hani communications,
                      simply click on the "unsubscribe" link provided in emails.
                      We may send SMS text messages related to our Services. You
                      can opt-out of these messages sent by Masjid Umme Hani at
                      any time by responding with STOP.
                    </p>

                    <h2>Payments</h2>

                    <p>
                      We process donations through Stripe, a reputable payment
                      processor known for its robust security measures. It's
                      important to note that Masjid Umme Hani does not store
                      your payment information for future visits. All payment
                      details are securely handled by Stripe for each
                      transaction, ensuring the confidentiality and integrity of
                      your financial data. For more information on how your
                      payment information is handled by Stripe, please refer to
                      their{" "}
                      <a href="https://www.masjidummehani.org/terms-of-services">
                        Terms and Conditions
                      </a>{" "}
                      and{" "}
                      <a href="https://www.masjidummehani.org/privacy-policy">
                        Privacy Policy
                      </a>{" "}
                      on their official website.
                    </p>

                    <h2>Age Restriction</h2>

                    <p>
                      Our website is not designed for individuals under the age
                      of 13. We do not knowingly gather personal information
                      from anyone under the age of 13. If you suspect that we
                      have inadvertently collected personal information from a
                      child under 13 without parental consent, please reach out
                      to us, and we will promptly take steps to remove the
                      information.
                    </p>

                    <h2>Intellectual Property</h2>

                    <p>
                      The content within our application(s) and website(s) is
                      protected by copyright. Prior consent from the copyright
                      holder is required before reproducing or utilizing any
                      images/graphics sourced from our websites or applications.
                    </p>

                    <h2>Changes to Our Privacy Notice</h2>

                    <p>
                      Our privacy practices undergo periodic reviews and may
                      evolve. Updates or modifications take effect immediately
                      upon posting on our Site. Notification of material changes
                      is made by posting on our Site's homepage or sending an
                      email to your associated email address. We encourage you
                      to check this page periodically for the most current
                      version of our Privacy Notice.
                    </p>

                    <h2>Contact Us</h2>

                    <p>
                      If you have any questions, concerns, or inquiries
                      regarding our privacy practices or your Personal Data,
                      please feel free to reach out to us.
                    </p>

                    <p>
                      <strong>Email:</strong>{" "}
                      <a href="mailto:munacentersf@gmail.com">
                        munacentersf@gmail.com
                      </a>
                      <br />
                      <strong>Phone:</strong> +1 (786) 523-6013
                      <br />
                      <strong>Address:</strong> 18215 NW 18th Ave, Miami
                      Gardens, Florida 33056
                    </p>

                    {/* ======= */}

                    {/* <p>
                      <strong>Limited Rights of License:</strong> Your
                      obtainment and/or use of the Platform does not grant you
                      any rights of ownership in the Platform, as all rights
                      granted are merely those of a licensee under the terms of
                      this Agreement, with those rights granted only for such
                      time as:
                    </p>

                    <p>
                      a. All trade secrets, and all trade secret rights and
                      equivalent rights arising b. All works of authorship,
                      including all registered and unregistered copyrights d. As
                      the same may arise or exist under common law, state law,
                      federal law and laws of foreign countries (collectively
                      “Intellectual Property Rights”).
                    </p>

                    <h2>2 - Payments</h2>
                    <p>
                      <strong>Transaction Fee:</strong> The fees owed with
                      respect to the rights and licenses granted hereunder shall
                      be no more than 4 percent (%4.000) per unique credit card
                      donation. A bill will be created once a month with those
                      aggregate fees. These fees do not include Stripe’s
                      processing fees, which will also be deducted immediately
                      at the time of the transaction processing.
                    </p>

                    <p>
                      <strong>Taxes:</strong> The fees charged do not include
                      any foreign, federal, state or local sales, use or other
                      similar taxes, however designated, levied against the
                      sale, licensing, delivery or use of the Platform. You
                      agree to pay, or reimburse, DEENFUND for all such taxes
                      imposed, provided, however, that you shall not be liable
                      for any taxes based on DEENFUND net income. You agree to
                      promptly notify us of any amendment or revocation of such
                      certificate, and will hold DEENFUND harmless from and
                      against any taxes or other monies resulting from the
                      failure to hold a certificate recognized as valid by the
                      appropriate tax authority.
                    </p>

                    <h2>3 - Terms and Termination</h2>
                    <p>
                      <strong>Termination.</strong> DEENFUND may terminate your
                      access to all or any part of the Website at any time, with
                      or without cause, with or without notice, effective
                      immediately. If you wish to terminate this Agreement or
                      your TopNonprofits.com account (if you have one), you may
                      simply discontinue using the Website. DEENFUND can
                      terminate the Website immediately as part of a general
                      shut down of our service. All provisions of this Agreement
                      which by their nature should survive termination shall
                      survive termination, including, without limitation,
                      ownership provisions, warranty disclaimers, indemnity and
                      limitations of liability.
                      <br />
                      <br />
                      Without limiting the foregoing, you agree to not use the
                      Services to establish or contribute to any Organizations
                      with the implicit or express purpose relating to any of
                      the following:
                    </p>

                    <p>
                      <strong>Productivity:</strong> They offer a productive
                      process that allows to experiment with solutions instead
                      of procrastinating forever.
                    </p>
                    <p>
                      <strong>Low risk:</strong> They offer a self-contained
                      space where failure carries a low risk, no stigma and thus
                      help propagate an experimental mindset: try things and see
                      if they work instead of racking your brain trying to make
                      the one, right decision (which is impossible).
                    </p>
                    <p>
                      They compress the time needed from conceptualizing
                      solutions to testing them into a few days.
                    </p>

                    <h2>4 - Disclaimer of Warranties. </h2>
                    <p>
                      The Website is provided “as is”. DEENFUND and its
                      suppliers and licensors hereby disclaim all warranties of
                      any kind, express or implied, including, without
                      limitation, the warranties of merchantability, fitness for
                      a particular purpose and non-infringement. Neither
                      DEENFUND nor its suppliers and licensors, makes any
                      warranty that the Website will be error free or that
                      access thereto will be continuous or uninterrupted. You
                      understand that you download from, or otherwise obtain
                      content or services through, the Website at your own
                      discretion and risk.
                    </p>

                    <p>
                      1. What do different age groups want from ads?
                      <br />
                      2. What are the most effective kinds of media? <br /> 3.
                      Why is online attention so expensive? <br />
                      4. How can brands be certain their ads are driving
                      consumer behavior?
                    </p>

                    <h2>
                      5 - Intellectual Property Notices and Confidentiality 
                    </h2>
                    <p>
                      Declining viewership means the death of TV is close – or
                      at least, that’s what some commentators would have us
                      believe. But even in this post-COVID digital age,
                      broadcast TV remains more popular than its online
                      alternative.
                      <br />
                      <br />
                      But it’s not just its popularity that makes TV so
                      appealing to advertisers, it’s the way in which it
                      provokes an emotional response from consumers.
                    </p> */}
                  </div>
                </Grid>
              </Grid>
            </div>
          }
        />
      </div>
      <Footer />
    </div>
  );
}

export default Tos;
