import axios from "axios";

const orgApiKey = process.env.REACT_APP_ORGANIZATION_API_KEY;
const orgBaseUrl = process.env.REACT_APP_BASEURL_DEV_API;

export function getEvents(id, application) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${orgBaseUrl}/dw-public/getEvents?orgId=${id}&application=${application}`,
        {
          headers: {
            token: orgApiKey,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getVideos(id, application) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${orgBaseUrl}/dw-public/getVideos?orgId=${id}&application=${application}`,
        {
          headers: {
            token: orgApiKey,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getSalahs(id, website) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${orgBaseUrl}/dw-public/getSalahs?orgId=60acdc878253b30008a83428&application=${website}`,
        {
          headers: {
            token: orgApiKey,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getOrganization(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${orgBaseUrl}/dw-public/getOrganization?orgId=60acdc878253b30008a83428`,
        {
          headers: {
            token: orgApiKey,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getSingleEvent(orgId, id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/dw-public/getSingleEvent/${orgId}/${id}`, {
        headers: {
          token: orgApiKey,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
