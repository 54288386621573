import React, { useState } from "react";
import adOneImg from '../assets/imgs/adOne.png'

function AdOne(props) {




    return (
        <a className="AdOne"><img src={adOneImg} /> </a>
    );
}


export default AdOne;