import React, { createRef, useState, useEffect } from "react";
import { AccordionOne } from "master-react-lib";
import Title from "./Title";
import Grid from "@material-ui/core/Grid";
import user_1 from "../assets/imgs/Daily-and-Jumuah-Prayers.png";
import user_2 from "../assets/imgs/Ramadan-Iftar-and-Tarawih.png";
import user_3 from "../assets/imgs/Eid-Prayers.png";
import user_4 from "../assets/imgs/Monthly-Family-Night.png";

function ServicesForTheComunity(props) {
  const [expanded, setExpanded] = React.useState("1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const servicesData = [
    {
      panel: "1",
      title: "Daily and Jumu'ah prayers",
      img: user_1,
      description: (
        <div>
          <p>
            Masjid Umme Hani is open for the 5 daily prayers including the
            Friday Jumu’ah prayers. The Jumu’ah prayer at our Masjid is led by
            well-renowned Sheikhs, to bring the Muslim community together. At
            our Masjid, we offer a separate place dedicated solely to our
            sisters where they can pray without any distractions or
            interruptions.
            <br /> <br />
            Abu Huraira reported Allah’s Messenger (may peace be upon him) as
            saying: He who performed ablution well, then came to Friday prayer,
            listened (to the sermon), kept silence all (his sins) between that
            time and the next Friday would be forgiven with three days extra,
            and he who touched pebbles caused an interruption. (Sahih Muslim)
            {/* <br /> <br />
            We’ve been the first to financially support Albastru, a project
            about the color blue and all its technical and cultural secrets.
            Diana, the project’s leader, is an independent videographer who
            travels up and down Romania to discover more about various shades of
            blue. On top of that, she used Felt, our theme, to create her
            website. */}
          </p>
        </div>
      ),
    },

    {
      panel: "2",
      title: "Interfaith dialogues",
      img: user_1,
      description: (
        <div>
          <p>
            Masjid Umme Hani offers interfaith dialogue service that provides
            understanding and mutual respect among people of different faiths.
            It is a safe and welcoming space for individuals from diverse
            religious backgrounds to come together and engage in meaningful
            conversations about their beliefs, values, and experiences.
            <br /> <br />
            During our interfaith dialogue sessions, everyone shares their
            personal stories and perspectives, asks questions, and engages in
            beneficial conversations with each other. We ensure to provide an
            atmosphere of mutual respect, where everyone feels heard and valued,
            it is a vital part of building a more just and compassionate world.
          </p>
        </div>
      ),
    },

    {
      panel: "3",
      title: "Family counselling",
      img: user_1,
      description: (
        <div>
          <p>
            Our counsellors at Masjid Umme Hani provide a confidential and
            supportive environment for families to work through their challenges
            and strengthen their relationships. Our approach is grounded in
            Islamic principles and values, allowing us to provide support that
            is both effective and in line with your beliefs.
          </p>
        </div>
      ),
    },

    {
      panel: "4",
      title: "Social welfare activities - muslims and non muslims",
      img: user_1,
      description: (
        <div>
          <p>
            Masjid Umme Hani offers social welfare activities to both Muslims
            and non-Muslims to make a positive impact on society. Our mission is
            to create a better world for all, regardless of their religious or
            cultural backgrounds. From feeding the hungry and providing shelter
            to those in need, to organizing educational programs and healthcare
            services, we believe in creating a better world for all.
          </p>
        </div>
      ),
    },

    {
      panel: "5",
      title: "Shahadah",
      img: user_1,
      description: (
        <div>
          <p>
            By taking your declaration of faith/shahada, you are making a
            powerful declaration of faith, it is probably the most important
            moment of your life, one that marks the beginning of a new chapter
            in your life. It is a formal acknowledgement of your acceptance of
            Islam, and an affirmation of your commitment to follow the path of
            Allah.
            <br />
            <br />
            So if you are ready to start this journey, or simply want to learn
            more about Islam, we invite you to visit Masjid Umme Hani or Contact
            Us and discover all that we have to offer. With our guidance and
            support, you can take the first steps towards a life filled with
            purpose, meaning, and spiritual fulfilment.
          </p>
        </div>
      ),
    },

    // {
    //   panel: "6",
    //   title: "Ramadhan iftar and tarawih",
    //   img: user_2,
    //   description: (
    //     <div>
    //       <p>
    //         Our mission at Pixelgrade is to support people who want to make an
    //         impact in their communities. We do that in various ways, but we’re
    //         always happy when we can contribute to the betterment of a local
    //         tribe. Let me give you a few examples of how we invest in local
    //         creatives in a way that’s aligned with both our mission and values.{" "}
    //         <br /> <br />
    //         For instance, all our hardwood desks are designed and made by the
    //         lovely team at Eterra Atelier btw their website is made with Fargo,
    //         our gem. Even if seven years have passed since we moved our office
    //         into an old house, they still look and function amazingly well.
    //         We’re in love with their craft and attention to detail, so thank
    //         you! <br /> <br />
    //         We’ve been the first to financially support Albastru, a project
    //         about the color blue and all its technical and cultural secrets.
    //         Diana, the project’s leader, is an independent videographer who
    //         travels up and down Romania to discover more about various shades of
    //         blue. On top of that, she used Felt, our theme, to create her
    //         website.
    //       </p>
    //     </div>
    //   ),
    // },
    // {
    //   panel: "7",
    //   title: "Weekend and summer school",
    //   img: user_3,
    //   description: (
    //     <div>
    //       <p>
    //         Our mission at Pixelgrade is to support people who want to make an
    //         impact in their communities. We do that in various ways, but we’re
    //         always happy when we can contribute to the betterment of a local
    //         tribe. Let me give you a few examples of how we invest in local
    //         creatives in a way that’s aligned with both our mission and values.{" "}
    //         <br /> <br />
    //         For instance, all our hardwood desks are designed and made by the
    //         lovely team at Eterra Atelier btw their website is made with Fargo,
    //         our gem. Even if seven years have passed since we moved our office
    //         into an old house, they still look and function amazingly well.
    //         We’re in love with their craft and attention to detail, so thank
    //         you! <br /> <br />
    //         We’ve been the first to financially support Albastru, a project
    //         about the color blue and all its technical and cultural secrets.
    //         Diana, the project’s leader, is an independent videographer who
    //         travels up and down Romania to discover more about various shades of
    //         blue. On top of that, she used Felt, our theme, to create her
    //         website.
    //       </p>
    //     </div>
    //   ),
    // },
    // {
    //   panel: "8",
    //   title: "Monthly/weekly family night",
    //   img: user_4,
    //   description: (
    //     <div>
    //       <p>
    //         Our mission at Pixelgrade is to support people who want to make an
    //         impact in their communities. We do that in various ways, but we’re
    //         always happy when we can contribute to the betterment of a local
    //         tribe. Let me give you a few examples of how we invest in local
    //         creatives in a way that’s aligned with both our mission and values.{" "}
    //         <br /> <br />
    //         For instance, all our hardwood desks are designed and made by the
    //         lovely team at Eterra Atelier btw their website is made with Fargo,
    //         our gem. Even if seven years have passed since we moved our office
    //         into an old house, they still look and function amazingly well.
    //         We’re in love with their craft and attention to detail, so thank
    //         you! <br /> <br />
    //         We’ve been the first to financially support Albastru, a project
    //         about the color blue and all its technical and cultural secrets.
    //         Diana, the project’s leader, is an independent videographer who
    //         travels up and down Romania to discover more about various shades of
    //         blue. On top of that, she used Felt, our theme, to create her
    //         website.
    //       </p>
    //     </div>
    //   ),
    // },
  ];

  const services = createRef();

  useEffect(() => {
    setTimeout(() => {
      if (window.location.href.indexOf("/about-us#our-services") > -1) {
        services && services.current.scrollIntoView({ behavior: "smooth" });
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    }, 1000);
  }, [services]);

  return (
    <div className="SectionContainer" ref={services}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} className="noBtn">
          <Title
            Title="SERVICES FOR THE COMMUNITY"
            ButtonStatus="false"
            arrowStatus={false}
            background="#0B132B"
          />
        </Grid>

        <Grid item xs={12} md={12}>
          {servicesData.map((item, i) => (
            <AccordionOne
              title={item.title}
              panel={item.panel}
              expanded={expanded}
              handleChange={handleChange}
              ////// Styling ////////
              titleColor="#0B132B"
              titleFontSize="20px"
              TitleFontWeight="600"
              AccordionBody={
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <div className="CmuImg">
                      <img src={item.img} />
                    </div>
                  </Grid>

                  <Grid item xs={12} md={8}>
                    {item.description}
                  </Grid>
                </Grid>
              }
            />
          ))}
        </Grid>
      </Grid>
    </div>
  );
}

export default ServicesForTheComunity;
