import React, { useEffect, useState } from "react";
import { Section, CardSix, NoAvailableEvents } from "master-react-lib";
import Grid from "@material-ui/core/Grid";
import Title from "./Title";
import bannerImg from "../assets/imgs/banner.png";
import { getEvents } from "./Functions";
const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function UpcomingEvents(props) {
  const [showNoEvent, setShowNoEvent] = useState(true);
  const [eventData, setEventData] = useState([]);
  useEffect(async () => {
    const events = await getEvents(orgId, "deenweb");
    let temp = [];

    if (events.data.body.data) {
      events.data.body.data.map((element) => {
        let time = element.event.eventStart.startAt.split("T");
        const timeZone = element.event.eventZone;
        const [year, month, day] = time[0].split("-");
        const Time = time[1].split(".")[0];
        const [DAY, DATE, MONTH] = new Date(year, month - 1, day)
          .toUTCString()
          .split(" ");
        const ExactTime = Time.split(":");
        const newTime = new Date(
          `${month}/${day}/${year} ${+ExactTime[0] + 5}:${ExactTime[1]}:${
            ExactTime[2]
          }`
        )
          .toLocaleTimeString()
          .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
        const dataString = `${DAY} ${MONTH} ${day} AT ${newTime} ${timeZone}`;

        let obj = {
          img: element.event.imageDetails.imageUrl,
          link: "/events-details?q=" + element?.event?._id,
          DateTime: dataString,
          title: element.event.title,
          order: element.event.order,
          eventStatus: element.event.published ? "Online" : "Offline",
        };
        element.featured && temp.push(obj) && setShowNoEvent(false);
      });

      temp.sort((a, b) => a.order - b.order);
      setEventData(temp);
    }
  }, []);

  return (
    <>
      {!showNoEvent && (
        <div className="InnerSection">
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Title
                Title="UPCOMING EVENTS"
                ButtonLabel="All Events"
                link="/events"
                ButtonStatus="true"
                arrowStatus={true}
                background="#0B132B"
              />
            </Grid>

            {eventData.map((item, i) => (
              <Grid item xs={12} md={4} sm={6}>
                <CardSix
                  img={item.img}
                  link={item.link}
                  title={item.title}
                  DateTime={item.DateTime}
                  eventStatus={item.eventStatus}
                  buttonLabel="Event Details"
                  btnColor="#029930"
                />
              </Grid>
            ))}

            {/* {showNoEvent && (
              <Grid item xs={12} md={12}>
                <NoAvailableEvents
                  Title="Oops! No available events"
                  Description="In the meantime, our secured donation form is superbly ready as we continuously need your generous donations. Please click on the button below to support us."
                  background="#fff"
                  //// Button Style ////
                  buttonLebel="I want to donate"
                  link="https://give.deenfund.com/masjid-umme-hani"
                  target="_blank"
                  arrowIcon={<ArrowForwardRoundedIcon />}
                  arrowStatus={true}
                  arrowColor="#fff"
                  buttoncolor="#029930"
                  width="200px"
                  height="50px"
                  border="#ccc 1px solid"
                  borderRadius="4px"
                  colorlabel="#fff"
                  fontWeight="600"
                  textAlign="center"
                />
              </Grid>
            )} */}
          </Grid>
        </div>
      )}
    </>
  );
}

export default UpcomingEvents;
